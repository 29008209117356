import { v4 as uuid } from 'uuid'
import moment from 'moment'
import { toast } from 'react-hot-toast'
import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit'
import api from '../../api'
import {
  activeProjectId, ensureUserProject, selectIsDefaultProject, selectIsMyProject,
} from '../projects/projectsSlice'
import Data, { buildIndex, findIndex } from '../network/indexing'
import { combineFilters, cropByTableFilters } from '../network/filtration'
import {
  selectSitesFull,
  selectSites,
  selectSiteFldIdx,
  selectSiteFields,
  selectSectorFields,
  selectSectorsFull,
  selectBaseStationsFull,
  selectBaseStationFields,
  getNetworkItemsNotAvailableInAtoll,
  selectSectorFldIdx,
} from '../network/networkSlice'
import { selectPanel, setPanel } from '../panel/panelSlice'
import { createNotifications } from '../notifications/notificationsSlice'
import {
  SITE_NAME_FIELD,
  SITE_ADDRESS_FIELD,
  ID_NETWORK_BC,
  SECTOR_SITE_FIELD,
  RBS_SITE_FIELD,
  RBS_NAME_FIELD,
  SECTOR_NAME_FIELD,
  STATUS_DRAFT,
  SECTOR_TECH_FIELD,
  SECTOR_STATUS_FIELD,
  STATUS_ACTIVE,
} from '../../constants/network'
import { buildElementsTree } from '../network/menus'
import { saveSettings, selectSettings } from '../settings/settingsSlice'
import { invertProp } from '../../utils/tree'
import { errorFixing } from '../../utils/format'
import { selectUser, selectUserAccessMemo } from '../login/loginSlice'
import { userIsFPA } from '../login/utils'
import { DATE_FORMAT } from '../../components/Tables/utils'
import { BUSINESS_CASES } from '../../constants/access'
import { EVENT_TYPE, saveEventLog, STATUS_ERROR, wrapByEventLog } from '../eventLog/eventLogSlice'
import { JOB_ACTIVE_STATUS, JOB_TYPE, STATUS_JOB } from '../updateProject/update'
import { setTaskCompleted, setTaskFailed } from '../taskLog/taskLogSlice'
import { doLoadJobs, getJobStatus } from '../synchronization/synchronizationSlice'
import { saveFilterLog } from '../filters/filters'
import {
  BC_ID_FIELD, BC_NAME_FIELD, BC_GROUP_ID_FIELD, BC_GROUP_NAME_FIELD,
  BC_SELECTED_FIELD, BC_EX_STATUS_FIELD, BC_SITE_FIELD, BC_COMMENTS_FIELD,
  EXISTING_STATUS_ID, BC_SITE_ID_FIELD, BC_PROJECT_ADDRESS,
  BC_SITE_PROJECT_ID_FIELD, BC_CALC_METHOD_FIELD,
  BC_STATUS_NEW_NAME, BC_STATUS_ID, BC_INSTANCE_ID_FIELD,
  BC_STATUS_ID_FIELD, BC_STATUS_NAME_FIELD,
  BC_CORRESPONDENCE_FIELD, BC_CORRESPONDENCE_DATE_FIELD,
  BC_SECTOR_IDS_FIELD,
  BC_RBSS_FIELD,
  BC_SECTORS_FIELD,
  BC_DATE_CAPEX_FIELD,
  BC_DATE_REVENUE_FIELD,
  BC_NEIGHBORS_FIELD,
  REQUIRED_STATUS_FOR_DISABLE_ANY_CHANGES,
  CHANGE_STATUS_ON_IMPORT,
  FIELDS_TO_CHANGE_STATUS_ON_IMPORT,
  SORTED_TECHNOLOGIES,
  BC_CASE_TYPE_FIELD,
  CASE_TYPE_B2B,
  CALC_METHOD_NEW_SECTORS,
  BC_IMPORT_MAX_SECTORS,
  CALC_METHOD_POSTLAUNCH,
  CASE_TYPE_B2C,
} from './constants'
import {
  bcObjectToRow, rowToBCObject, getCreateBCFields, getBCFields, getEnumDefValue,
  parseToType, checkLimits, getNeightborSectorsTitle,
  bcRowToCreateBCRow,
} from './utils'

export const TOP_INDEX_BC = 5

const NO_PROJECT_MESSAGE = 'Canceled. Please create a new one or use an existing project.'

const initialState = {
  name: 'BC',
  createLoading: false,
  synchronizing: null,
  groupCaseNames: null,
  businessCaseNames: null,
  siteNames: null,
  businessCaseFields: [],
  businessCases: {
    loading: false,
    full: new Data(),
    list: new Data(),
    fields: [],
    filters: {},
    localFilters: null,
    filtering: false,
    redraw: 0,
    id: ID_NETWORK_BC,
    name: 'Business Cases',
    state: {
      icon: 'Money',
      selectable: false,
    },
    path: [ TOP_INDEX_BC ],
    groupBy: BC_GROUP_NAME_FIELD,
    defaultBC: null,
  },
  createBC: {
    loading: false,
    full: new Data(),
    list: new Data(),
    fields: [],
    filters: {},
    filtering: false,
    redraw: 0,
    modifiedValues: null,
    needToExportFields: null,
    editNeighborsForBC: null,
    modifiedNeighbors: null,
    isModifiedNeighbors: false,
    selectedModificationZone: null,
  },
  summaryLoading: false,
  summaryData: null,
  checkingCorrespondence: false,
  financialsLoading: false,
  financialsData: {
    fields: [],
    data: [],
  },
  calcCoefficients: {
    loading: false,
    categories: null,
  },
  differencesData: {
    fields: [],
    data: [],
  },
  differencesExpired: false,
  calculateForApproveMessage: null,
  bcSectorsLoading: false,
}

const getRoot = (state) => {
  const { businessCases } = (state.bc ? state.bc : state)
  return {
    children: [ null, null, null, null, null, businessCases ],
  }
}

const loading = (state) => {
  state.businessCases.loading = true
}

const cancelLoading = (state) => {
  state.businessCases.loading = false
  state.ready = true
}

const createLoading = (state) => {
  state.createBC.loading = true
}

const cancelCreateLoading = (state) => {
  state.createBC.loading = false
  state.ready = true
}

const summaryLoading = (state) => {
  state.summaryLoading = true
}

const financialsLoading = (state) => {
  state.financialsLoading = true
}

const bcSectorsLoading = (state) => {
  state.bcSectorsLoading = true
}

const cancelBCSectorsLoading = (state) => {
  state.bcSectorsLoading = false
}

const prepareBCData = (state, action) => {
  state.businessCases.loading = false
  state.businessCaseFields = action.payload.fields
  const fields = getBCFields(action.payload.fields)
  const bcNameFldIdx = findIndex(fields, BC_NAME_FIELD)
  state.businessCases.fields = fields.map((field) => ({
    ...field,
    editable: field.editableInList,
    hidden: field.hiddenInList,
  }))
  const modifiedData = {
    fields,
    data: [
      ...action.payload.data.map((row) => {
        const updated = [
          false,
          ...row,
        ]
        const name = updated[bcNameFldIdx]
        updated.isInMyProject =
          state.businessCases.defaultBC ? !state.businessCases.defaultBC.includes(name) : true
        return updated
      }),
    ],
  }
  buildBCIndexes(state, { ...modifiedData, dataType: 'businessCases' })
  state.ready = true
}

const updateBCIndexes = (state, fields, data, index, indexes, rebuildIndexes) => {
  rebuildIndexes.forEach((index) => {
    indexes[index] = buildIndex({ fields, data }, index)
  })
  state.businessCases.full = new Data(data, index, indexes)
}

const prepareListBCData = (state, action) => {
  const idFldIdx = findIndex(action.payload.fields, BC_ID_FIELD)
  if (idFldIdx < 0) {
    console.warn('No ID field in BC data')
    return
  }
  const list = state.businessCases.full
  const fields = state.businessCases.fields
  const index = list.getIndex()
  const indexes = list.getIndexes()
  const data = list.getList()
  const createFull = state.createBC.full
  const createFields = state.createBC.fields
  const createData = createFull.getList()
  let changes = false
  let createChanges = false
  const rebuildIndexes = []
  for (const row of action.payload.data) {
    const id = row[idFldIdx]
    const idx = list.findIndexById(id)
    if (!id || idx < 0) {
      console.warn('No ID in BC data or no such ID in list')
      continue
    }
    const oldRow = data[idx]
    if (!oldRow) {
      console.warn('No BC for the found index')
      continue
    }
    const newRow = [ oldRow[0], ...row ]
    if (fields.length !== oldRow.length || fields.length !== newRow.length) {
      console.warn('BC fields length mismatch')
      continue
    }
    for (let i = 0; i < fields.length; i++) {
      if (newRow[i] !== oldRow[i]) {
        changes = true
        oldRow[i] = newRow[i]
        const fieldId = fields[i].id
        if (indexes[fieldId] && !rebuildIndexes.includes(fieldId)) {
          rebuildIndexes.push(fieldId)
        }
      }
    }
    const createIdx = createFull.findIndexById(id)
    if (createIdx < 0) {
      console.warn('No ID in CreateBC list')
      continue
    }
    const createRow = createData?.[createIdx]
    if (!createRow) {
      console.warn('No CreateBC row')
      continue
    }
    const modifiedValues = state.createBC.modifiedValues?.[id]
    const newCreateRow = [ createRow[0], createRow[1], ...row ]
    for (let i = 0; i < createFields.length; i++) {
      const fieldId = createFields[i].id
      if (modifiedValues && modifiedValues[fieldId]) {
        continue
      }
      if (newCreateRow[i] !== createRow[i]) {
        createRow[i] = newCreateRow[i]
        createChanges = true
      }
    }
  }
  if (changes) {
    updateBCIndexes(state, fields, data, index, indexes, rebuildIndexes)
  }
  if (createChanges) {
    state.createBC.redraw++
  }
}

const doDeleteGC = createAsyncThunk(
  'BC/deleteGC',
  api.bc.deleteGC,
)

export const doDeleteBusinessCases = createAsyncThunk(
  'BC/deleteBusinessCases',
  api.bc.deleteBusinessCases,
)

export const doCopyGroupBusinessCase = createAsyncThunk(
  'BC/copyGroupBusinessCase',
  api.bc.copyGroupBusinessCase,
)

export const doGetAllBCData = createAsyncThunk(
  'BC/getAllBCData',
  api.bc.getAllBCData,
)

export const doGetDefaultBCData = createAsyncThunk(
  'BC/getGetDefaultBCData',
  api.bc.getAllBCData,
)

export const doGetListBCData = createAsyncThunk(
  'BC/getListBCData',
  api.bc.getListBCData,
)

export const doCheckCorrespondence = createAsyncThunk(
  'BC/checkCorrespondence',
  api.bc.checkCorrespondence,
)

export const doGetBCDataForGC = createAsyncThunk(
  'BC/getBCDataForGC',
  (payload, thunkAPI) => {
    const state = thunkAPI.getState()
    const createBcFields = selectCreateBCFields(state)
    const fields = createBcFields && createBcFields.length > 0
      ? createBcFields
      : getCreateBCFields(selectDefBCFields(state))
    const [ ,, gcIdIndex ] = selectBCFldIdx(state)
    const gcId = payload
    return {
      fields,
      data: gcId
        ? selectBCFull(state).getList().reduce((res, row) => {
          if (row[gcIdIndex] === gcId) {
            const updated = [
              false,
              EXISTING_STATUS_ID.SELECTED,
              ...row.slice(1),
            ]
            updated.isInMyProject = row.isInMyProject
            res.push(updated)
          }
          return res
        }, [])
        : [ bcObjectToRow(fields) ],
    }
  },
)

export const doGetBusinessCaseSummary = createAsyncThunk(
  'BC/getBusinessCaseSummary',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState()
    const businessCaseId = payload
    const data = selectBCFull(state)
    const fields = selectBCFields(state)
    const index = data.findIndexById(businessCaseId)
    const bcRow = data.getList()[index]
    const bcObject = rowToBCObject(bcRow, fields, null, null)

    const siteFldIdx = findIndex(fields, BC_SITE_ID_FIELD)
    const siteId = bcRow[siteFldIdx]
    if (siteId) {
      const sitesFull = selectSitesFull(state)
      const siteIndex = sitesFull.findIndexById(siteId)
      if (siteIndex >= 0) {
        const sitesFields = selectSiteFields(state)
        const siteAddressIdx = findIndex(sitesFields, SITE_ADDRESS_FIELD)
        bcObject[BC_PROJECT_ADDRESS] = sitesFull.getList()[siteIndex][siteAddressIdx]
      }
    }
    return {
      ...bcObject,
      row: index,
      isInMyProject: bcRow.isInMyProject,
    }
  },
)

export const doGetBusinessCaseFinancials = createAsyncThunk(
  'BC/getBusinessCaseFinancials',
  api.bc.getBusinessCaseFinancials,
)

export const doGetBusinessCaseDifferences = createAsyncThunk(
  'BC/getBusinessCaseDifferences',
  api.bc.getBusinessCaseDifferences,
)

export const doCreateGroupCase = createAsyncThunk(
  'BC/createGroupCase',
  api.bc.createGroupCase,
)

export const doCreateBusinessCases = createAsyncThunk(
  'BC/createBusinessCases',
  api.bc.createBusinessCases,
)

export const doAddBusinessCases = createAsyncThunk(
  'BC/addBusinessCases',
  (payload, thunkAPI) => {
    const state = thunkAPI.getState()
    const amount = payload
    const full = selectCreateBCFull(state)
    const fields = selectCreateBCFields(state)
    const newData = [
      ...full.getList(),
    ]
    for (let i = 0; i < amount; ++i) {
      newData.push([
        ...bcObjectToRow(fields),
      ])
    }
    return {
      fields: selectCreateBCFields(state),
      data: newData,
    }
  },
)

export const editCreateBCField = createAsyncThunk(
  'BC/editCreateBCField',
  (payload, thunkAPI) => {
    const state = thunkAPI.getState()
    const { id, field, value, modified } = payload
    const full = selectCreateBCFull(state)
    const currentIdx = full.findIndexById(id)

    const current = full.getList()[currentIdx]

    if (!current) {
      throw new Error('Item not found')
    }

    const fields = selectCreateBCFields(state)
    const fieldId = fields[field].id

    current[field] = value

    const bcIdField = findIndex(fields, BC_ID_FIELD)
    const selectedField = findIndex(fields, BC_SELECTED_FIELD)
    const bcNameField = findIndex(fields, BC_NAME_FIELD)

    let rebuildIndex = false

    if (value && fieldId === BC_NAME_FIELD) {
      const bcNames = selectBCNames(state)
      const bcIndex = bcNames.findIndex((bcName) => bcName.name === value)
      const existingStatusField = findIndex(fields, BC_EX_STATUS_FIELD)
      if (bcIndex >= 0) {
        const foundId = bcNames[bcIndex].id
        if (id !== foundId) {
          // The user has selected an existing BC (first row)
          current[existingStatusField] = EXISTING_STATUS_ID.EXISTING
          current[bcIdField] = bcNames[bcIndex].id
          rebuildIndex = true
        }
      } else if (current[existingStatusField] !== EXISTING_STATUS_ID.SELECTED &&
        current[existingStatusField] !== EXISTING_STATUS_ID.NEW) {
        current[existingStatusField] = EXISTING_STATUS_ID.NEW
        current[bcIdField] = uuid()
        rebuildIndex = true
      }
    }

    let modifiedValues = null
    if (field !== selectedField && (field !== bcNameField || id === current[bcIdField])) {
      modifiedValues = { [id]: modified }
    }

    return [ current, modifiedValues, rebuildIndex ]
  },
)

export const doCalculateDraft = createAsyncThunk(
  'BC/calculateDraft',
  api.bc.calculateDraft,
)

export const doRecalculateDraft = createAsyncThunk(
  'BC/recalculateDraft',
  api.bc.recalculateDraft,
)

export const doCalculateFake = createAsyncThunk(
  'BC/calculateFake',
  api.bc.calculateFake,
)

export const doCalculateForApprove = createAsyncThunk(
  'BC/calculateForApprove',
  api.bc.calculateForApprove,
)

export const doCancelCalculation = createAsyncThunk(
  'BC/cancelCalculation',
  api.bc.cancelCalculation,
)

export const doApprove = createAsyncThunk(
  'BC/approve',
  api.bc.approve,
)

export const doApproveDraft = createAsyncThunk(
  'BC/approveDraft',
  api.bc.approveDraft,
)

export const doReject = createAsyncThunk(
  'BC/reject',
  api.bc.reject,
)

export const doGetCalcCoefficients = createAsyncThunk(
  'BC/getCalculationCoefficients',
  api.bc.getCalculationCoefficients,
)

export const doUpdateCalcCoefficients = createAsyncThunk(
  'BC/updateCalculationCoefficients',
  api.bc.updateCalculationCoefficients,
)

export const getBCSectors = createAsyncThunk(
  'bc/getBCSectors',
  api.network.getSectors,
)

const buildBCIndexes = (state, bundle) => {
  const { dataType, data } = bundle
  const indexes = {
    [BC_NAME_FIELD]: buildIndex(bundle, BC_NAME_FIELD),
    [BC_GROUP_NAME_FIELD]: buildIndex(bundle, BC_GROUP_NAME_FIELD),
    [BC_SITE_FIELD]: buildIndex(bundle, BC_SITE_FIELD),
    [BC_SITE_ID_FIELD]: buildIndex(bundle, BC_SITE_ID_FIELD),
  }
  if (dataType === 'businessCases') {
    indexes[BC_STATUS_ID_FIELD] = buildIndex(bundle, BC_STATUS_ID_FIELD)
  }
  state[dataType].full = new Data(data, buildIndex(bundle, BC_ID_FIELD), indexes)
}

const createBCEdited = (state, action) => {
  const idFldIdx = findIndex(state.createBC.fields, BC_ID_FIELD)
  const [ result, newModification, rebuildIndex ] = action.payload

  if (rebuildIndex) {
    // Update main index
    const fields = state.createBC.fields
    const full = state.createBC.full
    const data = full.getList()
    const indexes = full.getIndexes()
    const index = buildIndex({ fields, data }, BC_ID_FIELD)
    state.createBC.full = new Data(data, index, indexes)
  }

  const id = result[idFldIdx]
  const idx = state.createBC.full.findIndexById(id)
  state.createBC.full.getList()[idx] = result
  state.createBC.list = new Data(
    state.createBC.list.getList(),
    buildIndex({
      fields: state.createBC.fields,
      data: state.createBC.list.getList(),
    }, BC_ID_FIELD),
  )

  if (newModification) {
    const res = state.createBC.modifiedValues || {}
    Object.keys(newModification).forEach((key) => {
      const oldModifications = res[key]
      res[key] = {
        ...(oldModifications || {}),
        ...newModification[key],
      }
    })
    state.createBC.modifiedValues = res
  }

  state.createBC.redraw++
  state.createBC.loading = false
}

const prepareCalcCoefficients = (state, action) => {
  state.calcCoefficients.loading = false
  const categories = action.payload.coefficients && action.payload.coefficients.reduce((agg, coeff) => {
    let { categoryId, category } = coeff
    if (!categoryId) {
      categoryId = 'general'
      category = 'General'
    }
    if (!agg[categoryId]) {
      agg[categoryId] = {
        id: categoryId,
        name: category,
        coefficients: [ coeff ],
      }
    } else {
      agg[categoryId].coefficients.push(coeff)
    }
    return agg
  }, [])
  state.calcCoefficients.categories = categories && Object.values(categories)
  state.ready = true
}

export const bcSlice = createSlice({
  name: 'BC',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setSynchronizing: (state, action) => {
      state.synchronizing = action.payload
    },
    clearNames: (state, action) => {
      state.groupCaseNames = null
      state.businessCaseNames = null
    },
    addGCNames: (state, action) => {
      state.groupCaseNames = [
        ...(state.groupCaseNames || []),
        ...action.payload,
      ]
    },
    addBCNames: (state, action) => {
      state.businessCaseNames = [
        ...(state.businessCaseNames || []),
        ...action.payload,
      ]
    },
    addSiteNames: (state, action) => {
      state.siteNames = action.payload
    },
    setBCList: (state, action) => {
      const { dataType, list, fields } = action.payload
      state[dataType].list = new Data(list, buildIndex({ dataType, fields, data: list }, BC_ID_FIELD))
      if (dataType === 'businessCases') {
        buildElementsTree(state[dataType], false, BC_ID_FIELD, BC_NAME_FIELD)
      }
    },
    setFiltering: (state, action) => {
      const { dataType, filtering } = action.payload
      if (dataType) {
        state[dataType].filtering = filtering
      }
    },
    addGroupFilters: (state, action) => {
      const { dataType, filter } = action.payload
      state[dataType].localFilters = filter
    },
    addFilters: (state, action) => {
      const { dataType, filter, reset } = action.payload
      combineFilters(state[dataType], filter, reset)
    },
    resetFilters: (state, action) => {
      const dataType = action.payload
      if (state[dataType].filters) {
        state[dataType].filters = {}
      }
    },
    clearSummaryData: (state) => {
      state.summaryData = null
    },
    addModifiedValues: (state, action) => {
      const res = state.createBC.modifiedValues || {}
      const newModification = action.payload
      Object.keys(newModification).forEach((key) => {
        const oldModifications = res[key]
        res[key] = {
          ...(oldModifications || {}),
          ...newModification[key],
        }
      })
      state.createBC.modifiedValues = res
    },
    clearModified: (state) => {
      state.createBC.modifiedValues = null
    },
    removeDeletedBC: (state, action) => {
      const { businessCaseIds, newBusinessCaseIds } = action
      const removedBCIds = [
        ...(businessCaseIds || []),
        ...(newBusinessCaseIds || []),
      ]
      if (removedBCIds.length > 0) {
        const idFldIdx = findIndex(state.createBC.fields, BC_ID_FIELD)
        const list = state.createBC.full.getList().filter((bc) => (!removedBCIds.includes(bc[idFldIdx])))
        buildBCIndexes(state, { data: list, fields: state.createBC.fields, dataType: 'createBC' })
        state.createBC.loading = false
        state.createBC.redraw++
        state.ready = true
      }
    },
    clearExistingBCNames: (state) => {
      const fields = state.createBC.fields
      const existingStatusField = findIndex(fields, BC_EX_STATUS_FIELD)
      const nameField = findIndex(fields, BC_NAME_FIELD)
      state.createBC.full.getList().forEach((bc) => {
        if (bc[existingStatusField] === EXISTING_STATUS_ID.EXISTING) {
          bc[nameField] = null
          bc[existingStatusField] = null
        }
      })
      state.createBC.list = new Data(
        state.createBC.list.getList(),
        buildIndex({
          fields: state.createBC.fields,
          data: state.createBC.list.getList(),
        }, BC_ID_FIELD),
      )
      state.createBC.redraw++
      state.createBC.loading = false
    },
    setNeedToExportFields: (state, action) => {
      state.createBC.needToExportFields = action.payload
    },
    expandTreeItem: (state, action) => {
      invertProp(state, action.payload, 'expanded', false, getRoot)
      const path = action.payload
      if (path?.length > 1) {
        // Ініціюємо спрацювання селектора на зміну стану дочірніх елементів дерева
        const rootTree = getRoot(state).children[path?.[0]]
        if (rootTree?.state) {
          rootTree.state = { ...rootTree.state }
        }
      }
    },
    setBCDifferencesExpired: (state, action) => {
      state.differencesExpired = action.payload
    },
    clearBCDifferencesData: (state) => {
      state.differencesData = {
        fields: [],
        data: [],
      }
    },
    setCalculateForApproveMessage: (state, action) => {
      state.calculateForApproveMessage = action.payload
    },
    redrawBusinessCases: (state) => {
      state.businessCases.redraw++
    },
    redrawCreateBC: (state) => {
      state.createBC.redraw++
    },
    showEditNeighbors: (state, action) => {
      state.createBC.editNeighborsForBC = action.payload
    },
    setModifiedNeighbors: (state, action) => {
      state.createBC.modifiedNeighbors = action.payload
    },
    setIsModifiedNeighbors: (state, action) => {
      state.createBC.isModifiedNeighbors = action.payload
    },
    setSelectedModificationZone: (state, action) => {
      state.createBC.selectedModificationZone = action.payload
    },
  },
  extraReducers: (builder) => builder
    .addCase(doGetListBCData.fulfilled, prepareListBCData)
    .addCase(doGetAllBCData.pending, loading)
    .addCase(doGetAllBCData.fulfilled, prepareBCData)
    .addCase(doGetAllBCData.rejected, cancelLoading)
    .addCase(doGetDefaultBCData.pending, loading)
    .addCase(doGetDefaultBCData.fulfilled, (state, action) => {
      state.businessCases.loading = false
      const fields = action.payload.fields
      if (fields) {
        const bcNameFldIdx = findIndex(fields, BC_NAME_FIELD)
        state.businessCases.defaultBC = action.payload.data?.map((row) => row[bcNameFldIdx])
      }
    })
    .addCase(doGetDefaultBCData.rejected, cancelLoading)
    .addCase(doGetBCDataForGC.pending, createLoading)
    .addCase(doGetBCDataForGC.fulfilled, (state, action) => {
      state.createBC.loading = false
      state.createBC.fields = action.payload.fields
      buildBCIndexes(state, { ...action.payload, dataType: 'createBC' })
      if (action.payload.fields) {
        const gcNameIdx = findIndex(action.payload.fields, BC_GROUP_NAME_FIELD)
        if (action.payload.fields[gcNameIdx]?.hidden) {
          state.createBC.needToExportFields = [ gcNameIdx ]
        }
      }
      state.ready = true
    })
    .addCase(doGetBCDataForGC.rejected, cancelCreateLoading)
    .addCase(doAddBusinessCases.pending, createLoading)
    .addCase(doAddBusinessCases.fulfilled, (state, action) => {
      state.createBC.loading = false
      buildBCIndexes(state, { ...action.payload, dataType: 'createBC' })
      state.ready = true
    })
    .addCase(doAddBusinessCases.rejected, cancelCreateLoading)
    .addCase(doDeleteGC.pending, createLoading)
    .addCase(doDeleteGC.fulfilled, cancelCreateLoading)
    .addCase(doDeleteGC.rejected, cancelCreateLoading)
    .addCase(doDeleteBusinessCases.pending, createLoading)
    .addCase(doDeleteBusinessCases.fulfilled, cancelCreateLoading)
    .addCase(doDeleteBusinessCases.rejected, cancelCreateLoading)
    .addCase(editCreateBCField.fulfilled, createBCEdited)
    .addCase(doCreateGroupCase.pending, createLoading)
    .addCase(doCreateGroupCase.fulfilled, cancelCreateLoading)
    .addCase(doCreateGroupCase.rejected, cancelCreateLoading)
    .addCase(doCreateBusinessCases.pending, createLoading)
    .addCase(doCreateBusinessCases.fulfilled, cancelCreateLoading)
    .addCase(doCreateBusinessCases.rejected, cancelCreateLoading)
    .addCase(doGetBusinessCaseSummary.pending, summaryLoading)
    .addCase(doGetBusinessCaseSummary.fulfilled, (state, action) => {
      state.summaryLoading = false
      state.summaryData = action.payload
      state.ready = true
    })
    .addCase(doGetBusinessCaseFinancials.pending, financialsLoading)
    .addCase(doGetBusinessCaseFinancials.fulfilled, (state, action) => {
      state.financialsLoading = false
      const data = action.payload.financials
      // Add total column
      state.financialsData = {
        fields: [
          ...data.fields.map((field) => ({
            ...field,
            editable: field.editableInSummary,
            hidden: field.hiddenInSummary,
          })),
          {
            id: 'table.bc.result.financials.total',
            label: 'Total',
            description: 'Total',
            type: 'double',
            page: 'Business Case Financials',
            editable: false,
            hidden: false,
          },
        ],
        data: data.data.map((item) => {
          return [
            ...item,
            item[0]?.toLowerCase().includes('cumulated')
              ? null
              : item.reduce((agg, value, index) => {
                if (index > 0) {
                  return agg + value
                }
                return agg
              }, 0),
          ]
        }),
      }
      state.ready = true
    })
    .addCase(doGetBusinessCaseFinancials.rejected, (state) => {
      state.financialsLoading = false
    })
    .addCase(doCheckCorrespondence.pending, (state) => {
      state.checkingCorrespondence = true
    })
    .addCase(doCheckCorrespondence.fulfilled, (state, action) => {
      const data = action.payload.rows
      if (data.length > 0) {
        // Update BCs
        const fields = state.businessCases.fields
        const groupIdField = findIndex(fields, BC_GROUP_ID_FIELD)
        const idField = findIndex(fields, BC_ID_FIELD)
        const instanceIdField = findIndex(fields, BC_INSTANCE_ID_FIELD)
        const correspondenceField = findIndex(fields, BC_CORRESPONDENCE_FIELD)
        const correspondenceDateField = findIndex(fields, BC_CORRESPONDENCE_DATE_FIELD)
        state.businessCases.full.getList().forEach((bc) => {
          const groupId = bc[groupIdField]
          const id = bc[idField]
          const instanceId = bc[instanceIdField]
          const found = data.find((item) => {
            return item.bcgId === groupId && item.bscId === id && item.bsciId === instanceId
          })
          if (found) {
            bc[correspondenceField] = found.correspondence
            bc[correspondenceDateField] = moment().toISOString()
          }
        })
        state.businessCases.list = new Data(
          state.businessCases.list.getList(),
          buildIndex({ fields, data: state.businessCases.list.getList() }, BC_ID_FIELD),
        )
        // state.businessCases.redraw++
      }
      state.checkingCorrespondence = false
    })
    .addCase(doCheckCorrespondence.rejected, (state) => {
      state.checkingCorrespondence = false
    })
    .addCase(doGetBusinessCaseDifferences.fulfilled, (state, action) => {
      const data = action.payload.rows
      state.differencesData = {
        fields: [
          {
            id: 'table.bc.result.differences.element',
            label: 'Network element',
            description: 'Network element',
            type: 'string',
            page: 'Business Case Differences',
            editable: false,
            hidden: false,
          },
          {
            id: 'table.bc.result.differences.parameter',
            label: 'Parameter',
            description: 'Parameter name',
            type: 'string',
            page: 'Business Case Differences',
            editable: false,
            hidden: false,
          },
          {
            id: 'table.bc.result.differences.prev',
            label: 'Actual value',
            description: 'Actual value',
            type: 'double',
            page: 'Business Case Differences',
            editable: false,
            hidden: false,
          },
          {
            id: 'table.bc.result.differences.current',
            label: 'BC value',
            description: 'BC value',
            type: 'double',
            page: 'Business Case Differences',
            editable: false,
            hidden: false,
          },
          {
            id: 'table.bc.result.differences.difference',
            label: 'Difference',
            description: 'Difference',
            type: 'double',
            page: 'Business Case Differences',
            editable: false,
            hidden: false,
          },
        ],
        data: data.map((item) => {
          return [
            item.sectorName || item.siteName || '',
            item.diffDescription,
            item.valueDefault,
            item.valueUser,
            item.valueDifference,
          ]
        }),
      }
      state.ready = true
    })
    .addCase(doCalculateDraft.pending, createLoading)
    .addCase(doCalculateDraft.fulfilled, cancelCreateLoading)
    .addCase(doCalculateDraft.rejected, cancelCreateLoading)
    .addCase(doCalculateFake.pending, createLoading)
    .addCase(doCalculateFake.fulfilled, cancelCreateLoading)
    .addCase(doCalculateFake.rejected, cancelCreateLoading)
    .addCase(doCalculateForApprove.pending, createLoading)
    .addCase(doCalculateForApprove.fulfilled, cancelCreateLoading)
    .addCase(doCalculateForApprove.rejected, cancelCreateLoading)
    .addCase(doCancelCalculation.pending, createLoading)
    .addCase(doCancelCalculation.fulfilled, cancelCreateLoading)
    .addCase(doCancelCalculation.rejected, cancelCreateLoading)
    .addCase(doApprove.pending, loading)
    .addCase(doApprove.fulfilled, cancelLoading)
    .addCase(doApprove.rejected, cancelLoading)
    .addCase(doApproveDraft.pending, createLoading)
    .addCase(doApproveDraft.fulfilled, cancelCreateLoading)
    .addCase(doApproveDraft.rejected, cancelCreateLoading)
    .addCase(doReject.pending, loading)
    .addCase(doReject.fulfilled, cancelLoading)
    .addCase(doReject.rejected, cancelLoading)
    .addCase(doGetCalcCoefficients.pending, (state) => {
      state.calcCoefficients.loading = true
    })
    .addCase(doGetCalcCoefficients.fulfilled, prepareCalcCoefficients)
    .addCase(doGetCalcCoefficients.rejected, (state, action) => {
      state.calcCoefficients.loading = false
      state.ready = true
    })
    .addCase(doUpdateCalcCoefficients.pending, (state) => {
      state.calcCoefficients.loading = true
    })
    .addCase(doUpdateCalcCoefficients.fulfilled, prepareCalcCoefficients)
    .addCase(doUpdateCalcCoefficients.rejected, (state) => {
      state.calcCoefficients.loading = false
      state.ready = true
    })
    .addCase(doCopyGroupBusinessCase.pending, loading)
    .addCase(doCopyGroupBusinessCase.fulfilled, cancelLoading)
    .addCase(doCopyGroupBusinessCase.rejected, cancelLoading)
    .addCase(getBCSectors.pending, bcSectorsLoading)
    .addCase(getBCSectors.fulfilled, cancelBCSectorsLoading)
    .addCase(getBCSectors.rejected, cancelBCSectorsLoading),
})

export const {
  setLoading,
  clearNames,
  addGCNames,
  addBCNames,
  addSiteNames,
  setBCList,
  setFiltering,
  addFilters,
  resetFilters,
  addGroupFilters,
  clearSummaryData,
  addModifiedValues,
  clearModified,
  clearExistingBCNames,
  expandTreeItem,
  setNeedToExportFields,
  removeDeletedBC,
  setBCDifferencesExpired,
  clearBCDifferencesData,
  setSynchronizing,
  setCalculateForApproveMessage,
  redrawBusinessCases,
  redrawCreateBC,
  showEditNeighbors,
  setModifiedNeighbors,
  setIsModifiedNeighbors,
  setSelectedModificationZone,
} = bcSlice.actions

export const selectGCNames = (state) => state.bc.groupCaseNames

export const selectBCNames = (state) => state.bc.businessCaseNames

export const selectSiteNames = (state) => state.bc.siteNames

export const selectDefBCFields = (state) => state.bc.businessCaseFields

export const selectLoading = (state) => state.bc.businessCases.loading

export const selectCheckingCorrespondence = (state) => state.bc.checkingCorrespondence

export const selectBCFull = (state) => state.bc.businessCases.full

export const selectBCList = (state) => state.bc.businessCases.list

export const selectBCFields = (state) => state.bc.businessCases.fields

export const selectBCLocalFilters = (state) => state.bc.businessCases.localFilters

export const selectBCRedraw = (state) => state.bc.businessCases.redraw

export const selectDefaultBC = (state) => state.bc.businessCases.defaultBC

// TODO delete
// export const selectFilters = (state) => state.bc.businessCases.filters
// export const selectFiltering = (state) => state.bc.businessCases.filtering

export const selectCreateLoading = (state) => state.bc.createBC.loading

export const selectCreateBCFields = (state) => state.bc.createBC.fields

export const selectNeedToExportFields = (state) => state.bc.createBC.needToExportFields

export const selectCreateBCList = (state) => state.bc.createBC.list

export const selectCreateBCFull = (state) => state.bc.createBC.full

export const selectCreateBCRedraw = (state) => state.bc.createBC.redraw

export const selectCreateBCModifiedValues = (state) => state.bc.createBC.modifiedValues

export const selectSummaryLoading = (state) => state.bc.summaryLoading

export const selectSummaryData = (state) => state.bc.summaryData

export const selectFinancialsLoading = (state) => state.bc.financialsLoading

export const selectFinancialsData = (state) => state.bc.financialsData

export const selectCalcCoefficientsLoading = (state) => state.bc.calcCoefficients.loading

export const selectCalcCoefficients = (state) => state.bc.calcCoefficients.categories

export const selectDifferencesData = (state) => state.bc.differencesData

export const selectDifferencesExpired = (state) => state.differencesExpired

export const selectSynchronizing = (state) => state.bc.synchronizing

export const selectBCContainer = (dataType) => (state) => state.bc[dataType]

export const selectbcSectorsLoading = (state) => state.bc.bcSectorsLoading

export const selectEditNeighborsForBC = (state) => state.bc.createBC.editNeighborsForBC

export const selectIsEditNeighborsOpened = (state) => !!state.bc.createBC.editNeighborsForBC

export const selectModifiedNeighbors = (state) => state.bc.createBC.modifiedNeighbors

export const selectIsModifiedNeighbors = (state) => state.bc.createBC.isModifiedNeighbors

export const selectSelectedModificationZone = (state) => state.bc.createBC.selectedModificationZone

export const selectBCFldIdx = (state) => {
  const fields = selectBCFields(state)
  return [
    findIndex(fields, BC_ID_FIELD),
    findIndex(fields, BC_NAME_FIELD),
    findIndex(fields, BC_GROUP_ID_FIELD),
    findIndex(fields, BC_GROUP_NAME_FIELD),
    findIndex(fields, BC_STATUS_ID_FIELD),
    findIndex(fields, BC_SITE_ID_FIELD),
    findIndex(fields, BC_SITE_FIELD),
    findIndex(fields, BC_COMMENTS_FIELD),
    findIndex(fields, BC_CALC_METHOD_FIELD),
    findIndex(fields, BC_SELECTED_FIELD),
    findIndex(fields, BC_INSTANCE_ID_FIELD),
  ]
}

export const selectCreateBCFldIdx = (state) => {
  const fields = selectCreateBCFields(state)
  return [
    findIndex(fields, BC_ID_FIELD),
    findIndex(fields, BC_NAME_FIELD),
    findIndex(fields, BC_GROUP_ID_FIELD),
    findIndex(fields, BC_GROUP_NAME_FIELD),
    findIndex(fields, BC_SELECTED_FIELD),
    findIndex(fields, BC_EX_STATUS_FIELD),
  ]
}

export const selectBCTree = (state) => [
  state.bc.businessCases,
]

export const selectCalculateForApproveMessage = (state) => state.bc.calculateForApproveMessage

export const selectIsBCFieldEditable = createSelector(
  selectUser,
  selectIsMyProject,
  selectIsDefaultProject,
  selectUserAccessMemo,
  selectDefaultBC,
  (user, isMyProject, isDefaultProject, userAccess, bcInDefaultProject) => {
    const isReadOnly = !userAccess[BUSINESS_CASES] || !isMyProject

    return (columns, list, table = 'businessCases', isSummary) => (column, row) => {
      if (column === undefined || row === undefined) {
        return false
      }

      const columnId = columns[column]?.id
      if (columnId === BC_SELECTED_FIELD) {
        // Checkbox must be editable
        return true
      }

      if (isReadOnly) {
        return false
      }

      const exStatusColIndex = findIndex(columns, BC_EX_STATUS_FIELD)
      const exStatus = list?.[row]?.[exStatusColIndex]
      if (table === 'createBC' && columnId === BC_SITE_FIELD && exStatus !== EXISTING_STATUS_ID.SELECTED) {
        return true
      }

      if (isDefaultProject) {
        // Default project is opened
        return false
      }

      const nameColIndex = findIndex(columns, BC_NAME_FIELD)
      const name = list?.[row]?.[nameColIndex]
      if (bcInDefaultProject?.includes(name)) {
        // BC is in Default project
        return false
      }

      if (!isMyProject && (columnId !== BC_COMMENTS_FIELD || !userIsFPA(user))) {
        // Allow to edit Comments only for FPA in shared projects
        return false
      }

      let result = isSummary
        ? columns[column]?.editableInSummary
        : (table === 'businessCases' ? columns[column]?.editableInList : columns[column]?.editable)

      const editableInBcMethods = columns[column]?.editableInBcMethods
      const calcMethodColIndex = findIndex(columns, BC_CALC_METHOD_FIELD)
      const statusFieldIdx = findIndex(columns, BC_STATUS_ID_FIELD)
      const calcMethod = list?.[row]?.[calcMethodColIndex]
      const status = list?.[row]?.[statusFieldIdx]

      if (table === 'createBC' && editableInBcMethods) {
        result = editableInBcMethods.includes(calcMethod)

        if (result && columnId === BC_NEIGHBORS_FIELD) {
          const sectorsColIndex = findIndex(columns, BC_SECTORS_FIELD)
          const sectors = list?.[row]?.[sectorsColIndex]
          if (!sectors?.length > 0) {
            result = false
          }
        }
      }

      if (REQUIRED_STATUS_FOR_DISABLE_ANY_CHANGES.includes(status)) {
        result = false
      }

      return result
    }
  },
)

export const prepareGCNames = (dispatch, getState) => {
  const state = getState()
  const full = selectBCFull(state)
  const indexex = full.getIndex(BC_GROUP_NAME_FIELD)
  const [ ,, groupCaseIdInd, groupCaseNameInd ] = selectBCFldIdx(state)
  const list = full.getList()
  if (indexex && groupCaseNameInd > 0) {
    const groupNames = Object.values(indexex).reduce((res, idx, i) => {
      if (i < list.length) {
        const id = list[idx][groupCaseIdInd]
        res[id] = { id, name: list[idx][groupCaseNameInd] }
      }
      return res
    }, {})
    dispatch(addGCNames(Object.values(groupNames)))
  }
}

export const prepareBCNames = (dispatch, getState) => {
  const state = getState()
  const full = selectBCFull(state)
  const indexex = full.getIndex(BC_NAME_FIELD)
  const [ bcIdIndex, bcNameInd ] = selectBCFldIdx(state)
  const list = full.getList()
  if (indexex && bcNameInd > 0) {
    const bcNames = Object.values(indexex).reduce((res, idx, i) => {
      if (i < list.length) {
        const id = list[idx][bcIdIndex]
        res[id] = { id, name: list[idx][bcNameInd] }
      }
      return res
    }, {})
    dispatch(addBCNames(Object.values(bcNames)))
  }
}

export class BCNeighborsInfo {
  constructor (count, title, data, sectorsCount) {
    this.count = count
    this.title = title
    this.data = data
    this.sectorsCount = sectorsCount
  }

  toString = () => this.data ? JSON.stringify(this.data, null, 2) : ''
}

const sortTechnologies = (value) => {
  const sortedValue = Object.keys(SORTED_TECHNOLOGIES).reduce((agg, tech) => {
    const techValues = SORTED_TECHNOLOGIES[tech]
    techValues.forEach((tv) => {
      if (tv && value[tv]) {
        const sortedSectors = Object.keys(value[tv]).sort().reduce((agg, key) => {
          agg[key] = value[tv][key].filter((v) => v) // filter out empty values
          return agg
        }, {})
        agg[tv] = sortedSectors
      }
    })
    return agg
  }, {})
  return sortedValue
}

export const getNeighborsInfo = (value) => {
  const sortedAndTruncatedValue = sortTechnologies(value)
  const { count, title, sectorsCount } = getNeightborSectorsTitle(sortedAndTruncatedValue)
  return new BCNeighborsInfo(count, title, sortedAndTruncatedValue, sectorsCount)
}

export const prepareBCNeighbors = (dispatch, getState) => {
  const state = getState()
  const full = selectBCFull(state)
  const fields = selectBCFields(state)
  const bcNeighborsFldIdx = findIndex(fields, BC_NEIGHBORS_FIELD)
  full.getList().forEach((bc) => {
    const neighbors = bc[bcNeighborsFldIdx]
    if (neighbors && !(neighbors instanceof BCNeighborsInfo)) {
      bc[bcNeighborsFldIdx] = getNeighborsInfo(neighbors)
    }
  })

  const createBCFull = selectCreateBCFull(state)
  if (createBCFull && createBCFull.getList()?.length > 0) {
    const fields = selectCreateBCFields(state)
    const bcNeighborsFldIdx = findIndex(fields, BC_NEIGHBORS_FIELD)
    createBCFull.getList().forEach((bc) => {
      const neighbors = bc[bcNeighborsFldIdx]
      if (neighbors && !(neighbors instanceof BCNeighborsInfo)) {
        bc[bcNeighborsFldIdx] = getNeighborsInfo(neighbors)
      }
    })
  }
}

export const isAllowCreateBC = (siteId, status, sectorsFull) => {
  if (status === STATUS_DRAFT || status === STATUS_ACTIVE) {
    const sectorIdxs = sectorsFull.findRangeByValue(siteId, SECTOR_SITE_FIELD)
    return sectorIdxs?.length > 0
  }
  return false
}

export const prepareSiteNames = (dispatch, getState) => {
  const state = getState()

  const userAccess = selectUserAccessMemo(state)
  if (!userAccess[BUSINESS_CASES]) {
    return
  }

  const full = selectSites(state)
  const [ siteIdIndex, siteNameInd,,, siteStatusInd ] = selectSiteFldIdx(state)

  const sectorsFull = selectSectorsFull(state)
  const list = full.getList()
  if (siteNameInd > 0) {
    const siteNamesArray = list.reduce((agg, site) => {
      const siteId = site[siteIdIndex]
      const status = site[siteStatusInd]

      const isAllowed = isAllowCreateBC(siteId, status, sectorsFull)
      if (isAllowed) {
        agg.push([ site[siteNameInd], site[siteIdIndex] ])
      }
      return agg
    }, [])
    siteNamesArray.sort((a, b) => a[0] > b[0] ? 1 : a[0] < b[0] ? -1 : 0)
    dispatch(addSiteNames(siteNamesArray.reduce((agg, siteName) => {
      agg[siteName[0]] = siteName[1]
      return agg
    }, {})))
  }
}

function sleep (time) {
  return new Promise((resolve) => {
    setTimeout(resolve, time || 30000)
  })
}

export const updateCalculateForApprove = async (dispatch, getState) => {
  const state = getState()
  const settings = selectSettings(state)?.bcCalcApprove
  if (settings) {
    const { jobId, selectedGroupCase, bcIds } = settings

    if (!selectedGroupCase || !bcIds) {
      return
    }

    await dispatch(saveEventLog(
      EVENT_TYPE.bcCalculateForApprove,
      `: Start a synchronization with Atoll. Project job: ${jobId}, BC ids: ${JSON.stringify(bcIds)}`,
    ))

    const updateBCStatus = async (selectedGroupCase, bcIds, status) => {
      const saveStatusResult = await dispatch(doCreateBusinessCases({
        groupCaseId: selectedGroupCase,
        businessCases: Object.keys(bcIds).map((bcId) => ({
          [BC_ID_FIELD]: bcId,
          [BC_STATUS_ID_FIELD]: status,
        })),
      }))

      if (saveStatusResult.error) {
        await dispatch(saveEventLog(
          EVENT_TYPE.bcCalculateForApprove,
          `: Synchronization with Atoll. An error while updating BC statuses\nERROR: ${saveStatusResult.error.message}`,
          STATUS_ERROR,
        ))
        errorFixing({ message: `Synchronization with Atoll\n${saveStatusResult.error.message}` })
        return false
      }
      return true
    }

    if (jobId) {
      dispatch(setSynchronizing({
        gcId: selectedGroupCase,
        bcIds,
      }))
      // Check project job
      let checkProjectJob = true
      while (checkProjectJob) {
        const { payload } = await dispatch(getJobStatus(jobId))
        const { id, status } = payload ?? {}

        if (!JOB_ACTIVE_STATUS.includes(status) && jobId === id) {
          if (status === STATUS_JOB.FINISHED_SUCCESS) {
            checkProjectJob = false
          } else {
            dispatch(setSynchronizing(null))
            dispatch(setTaskFailed(jobId))
            // Remove job info from settings
            dispatch(saveSettings({
              bcCalcApprove: {
                jobId: null,
                selectedGroupCase: null,
                bcIds: null,
              },
            }))
            errorFixing({ message: 'Synchronization with Atoll\nAn error occurred while synchronizing the project' })
            await dispatch(saveEventLog(
              EVENT_TYPE.bcCalculateForApprove,
              ': Synchronization with Atoll. An error occurred while synchronizing the project',
              STATUS_ERROR,
            ))
            // Restore BC state
            await updateBCStatus(selectedGroupCase, bcIds, BC_STATUS_ID.CALCULATE_SNC_DRAFT_SUCCESS)
            return
          }
        } else {
          // continue checking
          await sleep()
        }
      }
    }

    // Check the status of Default project synchronization
    await dispatch(saveEventLog(
      EVENT_TYPE.bcCalculateForApprove,
      ': Synchronization with Atoll. Check the status of Default project',
    ))

    let checkDefaultProject = true
    while (checkDefaultProject) {
      const { payload } = await dispatch(doLoadJobs())
      const defAtollJob = payload && payload?.length > 0 && payload.find((job) => {
        return job?.type === JOB_TYPE.SYNC_ATOLL_NETWORK
      })
      if (defAtollJob) {
        if (!JOB_ACTIVE_STATUS.includes(defAtollJob?.status)) {
          if (defAtollJob?.status === STATUS_JOB.FINISHED_SUCCESS) {
            checkDefaultProject = false
          } else {
            dispatch(setSynchronizing(null))
            dispatch(setTaskFailed(jobId))
            // Remove job info from settings
            dispatch(saveSettings({
              bcCalcApprove: {
                jobId: null,
                selectedGroupCase: null,
                bcIds: null,
              },
            }))
            await dispatch(saveEventLog(
              EVENT_TYPE.bcCalculateForApprove,
              ': Synchronization with Atoll. An error occurred while synchronizing the Default project',
              STATUS_ERROR,
            ))
            errorFixing({
              message: 'Synchronization with Atoll\nAn error occurred while synchronizing the Default project',
            })
            // Restore BC state
            await updateBCStatus(selectedGroupCase, bcIds, BC_STATUS_ID.CALCULATE_SNC_DRAFT_SUCCESS)
            return
          }
        } else {
          await sleep()
        }
      } else {
        // Default job not found, continue
        checkDefaultProject = false
      }
    }

    // Check network items
    await dispatch(saveEventLog(
      EVENT_TYPE.bcCalculateForApprove,
      ': Calculate for Approve. Check items not available in Atoll',
    ))

    const siteIds = Object.values(bcIds).map((siteId) => ({ siteId }))
    const result = await dispatch(getNetworkItemsNotAvailableInAtoll(siteIds))
    let startCalculation = false
    if (result !== null) {
      if (result.error) {
        await dispatch(saveEventLog(
          EVENT_TYPE.bcCalculateForApprove,
          `: Calculate for Approve. An error while checking network items not available in Atoll\nERROR: ${result.error}`,
          STATUS_ERROR,
        ))
        errorFixing({ message: `Synchronization with Atoll\n${result.error}` })
      } else {
        await dispatch(saveEventLog(
          EVENT_TYPE.bcCalculateForApprove,
          ': Calculate for Approve. Show network items not available in Atoll. Done',
        ))
        dispatch(setCalculateForApproveMessage(result))
      }
    } else {
      startCalculation = true
    }

    await dispatch(saveEventLog(
      EVENT_TYPE.bcCalculateForApprove,
      `: Calculate for Approve. Update BC status: ${startCalculation ? BC_STATUS_ID.NEW : BC_STATUS_ID.CALCULATE_SNC_DRAFT_SUCCESS}`,
    ))

    dispatch(setTaskCompleted(jobId))
    dispatch(setSynchronizing(null))
    // Remove job info from settings
    await dispatch(saveSettings({
      bcCalcApprove: {
        jobId: null,
        selectedGroupCase: null,
        bcIds: null,
      },
    }))

    // Update BC status
    const resultUpdateStatus =
      await updateBCStatus(
        selectedGroupCase, bcIds, startCalculation ? BC_STATUS_ID.NEW : BC_STATUS_ID.CALCULATE_SNC_DRAFT_SUCCESS)
    if (!resultUpdateStatus) {
      // An error occures
      return
    }

    if (startCalculation) {
      await dispatch(saveEventLog(
        EVENT_TYPE.bcCalculateForApprove,
        `: Start calculating for approval for GC: '${selectedGroupCase}', BCs: ${JSON.stringify(Object.keys(bcIds))}`,
      ))
      const res = await dispatch(calculateForApprove({
        gcId: selectedGroupCase,
        businessCaseIds: Object.keys(bcIds),
      }))
      if (res.error) {
        await dispatch(saveEventLog(
          EVENT_TYPE.bcCalculateForApprove,
          `: Calculating BC [GC = ${selectedGroupCase}]\nERROR: ${res.error}`,
          STATUS_ERROR,
        ))
        errorFixing({ message: `Calculate for approve\n${res.error}` })
      }
    }

    // Do Refresh
    await dispatch(loadAllBCData())

    return selectedGroupCase
  }
}

export const filterAllBCData = (dataType) => (dispatch, getState) => {
  dispatch(setFiltering({ dataType, filtering: true }))
  const currentState = getState()
  const full = currentState.bc[dataType].full
  const fields = currentState.bc[dataType].fields
  if (!full.getCache()) {
    full.setCache(full.getList())
  }
  const filters = currentState.bc[dataType].filters
  const list = cropByTableFilters({ filters }, full.getCache())
  dispatch(setBCList({ dataType, list, fields }))
  dispatch(setFiltering({ dataType, filtering: false }))
  dispatch(saveFilterLog(dataType, selectBCContainer(dataType)))
}

const processElement = (dispatch, loader, indexer, data) => {
  const { loaderParam, dataType } = data
  return dispatch(loader(loaderParam))
    .catch((err) => {
      console.error(err)
    })
    .then(() => dispatch(indexer(dataType)))
}

export const loadDefaultBCData = () => async (dispatch, getState) => {
  return await dispatch(doGetDefaultBCData('_'))
}

export const loadAllBCData = () => async (dispatch, getState) => {
  const currentState = getState()
  const dataType = 'businessCases'
  if (!currentState.bc[dataType].loading) {
    await Promise.all([
      processElement(dispatch, doGetAllBCData, filterAllBCData, {
        loaderParam: activeProjectId(currentState) ?? '_',
        dataType,
      }),
      dispatch(clearNames()),
    ])
    await Promise.all([
      dispatch(prepareGCNames),
      dispatch(prepareBCNames),
      dispatch(prepareBCNeighbors),
    ])
    dispatch(redrawBusinessCases())
  }
}

export const loadBusinessCasesForGC = (gcId) => async (dispatch, getState) => {
  const currentState = getState()
  const dataType = 'createBC'
  if (!currentState.bc[dataType].loading) {
    await Promise.all([
      processElement(dispatch, doGetBCDataForGC, filterAllBCData, { dataType, loaderParam: gcId }),
    ])
  }
}

export const loadBusinessCases = (firstBCName) => async (dispatch, getState) => {
  const currentState = getState()
  const dataType = 'createBC'
  if (!currentState.bc[dataType].loading) {
    const bcNames = selectBCNames(currentState)
    const bcId = bcNames.find((bcName) => bcName.name === firstBCName)?.id
    if (bcId) {
      const full = selectBCFull(currentState)
      const bc = full.findIndexById(bcId)
      const [ ,, groupCaseIdInd ] = selectBCFldIdx(currentState)
      const gcId = full.getList()[bc][groupCaseIdInd]
      await Promise.all([
        processElement(dispatch, doGetBCDataForGC, filterAllBCData, { dataType, loaderParam: gcId }),
      ])
      return gcId
    }
  }
  return null
}

export const clearCreateBCData = () => async (dispatch, getState) => {
  const currentState = getState()
  const dataType = 'createBC'
  if (!currentState.bc[dataType].loading) {
    await dispatch(wrapByEventLog({
      type: EVENT_TYPE.bcActions,
      details: 'Refresh data for GC',
      action: () => Promise.all([
        processElement(dispatch, doGetBCDataForGC, filterAllBCData, { dataType }),
      ]),
    }))
  }
}

export const addBusinessCases = (amount) => async (dispatch, getState) => {
  const currentState = getState()
  const dataType = 'createBC'
  if (!currentState.bc[dataType].loading) {
    await Promise.all([
      processElement(dispatch, doAddBusinessCases, filterAllBCData, { dataType, loaderParam: amount }),
    ])
  }
}

export const deleteBusinessCases = () => async (dispatch, getState) => {
  const currentState = getState()
  const dataType = 'createBC'
  if (!currentState.bc[dataType].loading) {
    const full = selectCreateBCFull(currentState)
    const [ bcIdIndx,, gcIdIndx,, selectedIndx, exStatusIndx ] = selectCreateBCFldIdx(currentState)
    let gcId = null
    const newBCIds = []
    const bcIds = full.getList().reduce((res, row) => {
      if (row[selectedIndx]) {
        const exStatus = row[exStatusIndx]
        if (exStatus === EXISTING_STATUS_ID.SELECTED) {
          if (!gcId) {
            gcId = row[gcIdIndx]
          }
          res.push(row[bcIdIndx])
        } else if (exStatus === EXISTING_STATUS_ID.NEW || !exStatus) {
          newBCIds.push(row[bcIdIndx])
        }
      }
      return res
    }, [])
    await Promise.all([
      processElement(dispatch, doDeleteBusinessCases, filterAllBCData, {
        dataType,
        loaderParam: {
          groupCaseId: gcId,
          businessCaseIds: bcIds,
          newBusinessCaseIds: newBCIds,
        },
      }),
    ])

    dispatch(removeDeletedBC({
      businessCaseIds: bcIds,
      newBusinessCaseIds: newBCIds,
    }))
  }
}

export const approveDraft = (projectsId, userIds) => async (dispatch, getState) => {
  const currentState = getState()
  const dataType = 'createBC'
  const bcNames = []
  if (!currentState.bc[dataType].loading) {
    const full = selectCreateBCFull(currentState)
    const fields = selectCreateBCFields(currentState)
    const [ bcIdIndx, bcNameFldIdx, gcIdIndx,, selectedIndx, exStatusIndx ] = selectCreateBCFldIdx(currentState)
    let groupCaseId = null
    const bcInstIdIndx = findIndex(fields, BC_INSTANCE_ID_FIELD)
    const bsciIds = []
    const businessCaseIds = full.getList().reduce((res, row) => {
      if (row[selectedIndx] && row[exStatusIndx] === EXISTING_STATUS_ID.SELECTED) {
        if (!groupCaseId) {
          groupCaseId = row[gcIdIndx]
        }
        bcNames.push(row[bcNameFldIdx])
        res.push(row[bcIdIndx])
        bsciIds.push(row[bcInstIdIndx])
      }
      return res
    }, [])
    await dispatch(createNotifications({ projectId: projectsId[0], userIds, bsciIds }))
    const result = await dispatch(doApproveDraft({
      groupCaseId,
      businessCaseIds,
    }))
    if (result.error) {
      console.error(result.error.message)
    }
  }
  return bcNames
}

export const createNewGroupCase = ({ id, name }) => (dispatch) => dispatch(wrapByEventLog({
  type: EVENT_TYPE.bcCreateGroupCase,
  details: `ID = ${id}; Name = ${name}`,
  action: async () => {
    const createId = id || uuid()
    const projectId = await dispatch(ensureUserProject())
    if (projectId === '_') {
      throw new Error('Default project is used while creating a new GC')
    }
    const res = await dispatch(doCreateGroupCase({ id: createId, name, projectId }))
    if (!res.error) {
      await dispatch(addGCNames([ res.payload ]))
      return { id: createId }
    } else {
      throw new Error(`Error when creating GC. Error: ${res.error}`)
    }
  },
  extractor: (result) => result && `ID = ${result.id}`,
}))

const getNewBusinessCases = async (state, projectId, saveAction) => {
  const list = selectCreateBCFull(state).getList()
  const createFields = selectCreateBCFields(state)
  const siteNames = selectSiteNames(state)
  const exStatusIndx = findIndex(createFields, BC_EX_STATUS_FIELD)
  const siteIdIndx = findIndex(createFields, BC_SITE_ID_FIELD)
  const selectedIndx = findIndex(createFields, BC_SELECTED_FIELD)
  const siteNameIndx = findIndex(createFields, BC_SITE_FIELD)

  return list.reduce((res, bc) => {
    if ((bc[selectedIndx] || saveAction) &&
      bc[siteNameIndx] && (!bc[exStatusIndx] || bc[exStatusIndx] === EXISTING_STATUS_ID.NEW)) {
      const siteId = bc[siteIdIndx] || siteNames[bc[siteNameIndx]]
      const bcObject = rowToBCObject(bc.slice(5), createFields.slice(5), siteId, projectId) // remove local status and BGC info
      res.push(bcObject)
    }
    return res
  }, [])
}

export const createNewBCs = (payload) => async (dispatch, getState) => {
  dispatch(setLoading(true))
  const state = getState()
  const { gcId } = payload
  const projectId = await dispatch(ensureUserProject())
  if (projectId === '_') {
    await dispatch(saveEventLog(
      EVENT_TYPE.bcCreate,
      ': Default project is used while creating new BCs',
      STATUS_ERROR,
    ))
    return {
      error: NO_PROJECT_MESSAGE,
    }
  }
  const businessCases = await getNewBusinessCases(state, projectId)
  const result = await dispatch(doCreateBusinessCases({
    groupCaseId: gcId,
    businessCases,
  }))
  if (!result.error) {
    return { id: gcId }
  } else {
    return {
      error: result.error.message,
    }
  }
}

export const saveBusinessCases = (payload) => async (dispatch, getState) => {
  dispatch(setLoading(true))
  const state = getState()
  if (!selectLoading(state)) {
    let { gcId, isSummary, businessCases } = payload
    const projectId = await dispatch(ensureUserProject())
    if (projectId === '_') {
      await dispatch(saveEventLog(
        EVENT_TYPE.bcSave,
        ': Default project is used while saving BCs',
        STATUS_ERROR,
      ))
      return {
        error: NO_PROJECT_MESSAGE,
      }
    }
    if (!businessCases) {
      const modifiedValues = selectCreateBCModifiedValues(state)
      const createfields = selectCreateBCFields(state)
      const exStatusIndx = findIndex(createfields, BC_EX_STATUS_FIELD)
      const data = isSummary ? selectBCFull(state) : selectCreateBCFull(state)
      const newBusinessCases = await getNewBusinessCases(state, projectId, true)
      const modifiedBusinessCases = modifiedValues
        ? Object.keys(modifiedValues).reduce((res, bcId) => {
          const index = data.findIndexById(bcId)
          if (index >= 0) {
            const bcRow = data.getList()[index]
            if (bcRow[exStatusIndx] === EXISTING_STATUS_ID.SELECTED || isSummary) {
              res.push({
                [BC_ID_FIELD]: bcId,
                ...modifiedValues[bcId],
              })
            }
          }
          return res
        }, [])
        : []
      businessCases = [
        ...newBusinessCases,
        ...modifiedBusinessCases,
      ]
    }
    const result = await dispatch(doCreateBusinessCases({
      groupCaseId: gcId,
      businessCases,
    }))
    if (!result.error) {
      return { id: gcId }
    } else {
      return {
        error: result.error.message,
      }
    }
  }
}

const hasDuplicates = (array) => {
  return (new Set(array)).size !== array.length
}

const hasDuplicateNeighbors = (value) => {
  if (!value) {
    return false
  }
  const technologies = Object.keys(value)
  if (technologies.length === 0) {
    return false
  } else if (hasDuplicates(technologies)) {
    return true
  }
  let result = false
  technologies.forEach((tech) => {
    if (!result) {
      const sectors = Object.keys(value[tech])
      if (sectors.length > 0 && hasDuplicates(sectors)) {
        result = true
      } else {
        sectors.forEach((sector) => {
          if (!result) {
            const neighbors = value[tech][sector]
            if (neighbors.length > 0 && hasDuplicates(neighbors)) {
              result = true
            }
          }
        })
      }
    }
  })

  return result
}

export const importBusinessCases = (payload) => async (dispatch, getState) => {
  const state = getState()
  if (!selectLoading(state)) {
    dispatch(setLoading(true))
    const { cols, rows } = payload

    const projectId = await dispatch(ensureUserProject())
    if (projectId === '_') {
      await dispatch(saveEventLog(
        EVENT_TYPE.bcImport,
        ': Default project is used while importing BCs',
        STATUS_ERROR,
      ))
      return {
        error: NO_PROJECT_MESSAGE,
      }
    }

    const nameColumn = cols.findIndex((col) => col?.id === BC_NAME_FIELD)
    const siteNameColumn = cols.findIndex((col) => col?.id === BC_SITE_FIELD)
    const gcColumn = cols.findIndex((col) => col?.id === BC_GROUP_NAME_FIELD)
    const capexDateColumn = cols.findIndex((col) => col?.id === BC_DATE_CAPEX_FIELD)
    const revenueDateColumn = cols.findIndex((col) => col?.id === BC_DATE_REVENUE_FIELD)
    const neighborsColumn = cols.findIndex((col) => col?.id === BC_NEIGHBORS_FIELD)
    const calcMathodColumn = cols.findIndex((col) => col?.id === BC_CALC_METHOD_FIELD)

    const createColumns = selectCreateBCFields(state)

    const isBCFieldEditableFunction = selectIsBCFieldEditable(state)
    const idxs = cols.map((col) => col ? (createColumns.findIndex(({ id }) => id === col.id)) : -1)
    const full = selectBCFull(state)
    const fields = selectBCFields(state)
    const sectorsFull = selectSectorsFull(state)
    const fieldIndexes = {}
    const [
      idFieldIdx, bcNameFldIdx, gcIdFieldIdx, gcNameFldIdx, statusIdFldIdx, bcSiteIdFieldIdx,
    ] = selectBCFldIdx(state)
    const [ siteIdFldIdx,,,, siteStatusFldIdx ] = selectSiteFldIdx(state)
    const [ sectorIdFldIdx, sectorNameFldIdx,,,,,,, sectorStatusFldIdx ] = selectSectorFldIdx(state)
    fieldIndexes[BC_GROUP_NAME_FIELD] = gcNameFldIdx
    fieldIndexes[BC_NAME_FIELD] = bcNameFldIdx
    fieldIndexes[BC_STATUS_ID_FIELD] = statusIdFldIdx
    const sitesFull = selectSitesFull(state)
    const groupCaseNames = selectGCNames(state)
    let noMandatoryValue = 0
    const toUpdate = {}
    const toCreate = {}
    let noSite = 0
    let duplicateSite = 0
    let incorrectDateFormat = 0
    let incorrectStartCapexDate = 0
    let incorrectStartRevenueDate = 0
    const incorrectValue = []
    const noItemValue = []
    const tooManyItemsValue = []
    let duplicateBCName = 0
    let duplicateNeighbors = 0
    let ignoredRows = 0
    let modificationNotAllowed = 0
    const alreadyUpdated = []
    const cantCreateBCG = []

    const validateSectors = (value = '', siteName, siteStatus, calcMathod) => {
      let valueTooManyItemsValidation = true
      let valueNoItemValidation = true
      let sectors = value && value.length > 0 ? value.split(',') : undefined // If no sectors
      if (sectors?.length > BC_IMPORT_MAX_SECTORS) {
        valueTooManyItemsValidation = false
      }
      const siteIdx = sitesFull.findRangeByValue(siteName, SITE_NAME_FIELD)
      const siteId = siteIdx?.[0] >= 0 && sitesFull.getList()?.[siteIdx[0]]?.[siteIdFldIdx]
      const sectorIndexes = siteId && sectorsFull.findRangeByValue(siteId, SECTOR_SITE_FIELD)
      const sectorIdsByName = sectorIndexes
        ? sectorIndexes.reduce((agg, sectorIdx) => {
          const sector = sectorsFull.getList()[sectorIdx]
          const status = sector[sectorStatusFldIdx]
          if (status === STATUS_DRAFT || (calcMathod === CALC_METHOD_POSTLAUNCH && siteStatus === STATUS_ACTIVE)) {
            agg[sector[sectorNameFldIdx]] = sector[sectorIdFldIdx]
          }
          return agg
        }, {})
        : {}
      const sectorIds = []
      sectors = sectors?.filter((sector) => {
        const includes = Object.keys(sectorIdsByName).includes(sector.trim())
        if (includes) {
          sectorIds.push(sectorIdsByName[sector.trim()])
        }
        return includes
      })
      if ((sectors === undefined && Object.keys(sectorIdsByName).length === 0) ||
          (sectors !== undefined && sectors.length === 0)) {
        valueNoItemValidation = false
      }

      return {
        valueTooManyItemsValidation,
        valueNoItemValidation,
        value: sectors?.join(','),
        sectorIds: sectorIds.join(','),
      }
    }

    const businessCases = rows && rows.reduce((businessCases, row, rowIndex) => {
      const notEmptyValueIndex = row.findIndex((item) => !!item || item === 0)
      if (notEmptyValueIndex === -1) {
        ignoredRows++
        return businessCases
      }
      if (row[nameColumn]) {
        row[nameColumn] = row[nameColumn].trim().toUpperCase()
      }
      if (row[siteNameColumn]) {
        row[siteNameColumn] = row[siteNameColumn].trim().toUpperCase()
      }
      if (row[gcColumn]) {
        row[gcColumn] = row[gcColumn].trim().toUpperCase()
      }
      const bcName = row[nameColumn]
      const siteName = row[siteNameColumn]
      const gcName = row[gcColumn]
      const calcMathod = row[calcMathodColumn]
      const capexDate = row[capexDateColumn]
      const revenueDate = row[revenueDateColumn]
      const neighbors = neighborsColumn >= 0 ? row[neighborsColumn] : null

      if (!siteName || !gcName) {
        noMandatoryValue++
      } else if (calcMathod !== CALC_METHOD_POSTLAUNCH && (!moment(capexDate, DATE_FORMAT, true).isValid() ||
        !moment(revenueDate, DATE_FORMAT, true).isValid()
      )) {
        incorrectDateFormat++
      } else if (calcMathod !== CALC_METHOD_POSTLAUNCH && moment(capexDate).isBefore(moment().startOf('day'))) {
        incorrectStartCapexDate++
      } else if (calcMathod !== CALC_METHOD_POSTLAUNCH && moment(revenueDate).isBefore(moment(capexDate).add(1, 'M'))) {
        incorrectStartRevenueDate++
      } else if (neighbors && hasDuplicateNeighbors(neighbors)) {
        duplicateNeighbors++
      } else {
        const bcNameIdx = full.findRangeByValue(bcName, BC_NAME_FIELD)
        if (bcNameIdx >= 0) {
          // BC with this name found. Updating BC
          const bc = full.getList()[bcNameIdx]
          const bcCopy = bcRowToCreateBCRow(bc)
          const origBCName = bc[bcNameFldIdx]
          if (alreadyUpdated.includes(origBCName)) {
            duplicateBCName++ // Duplicate BC name in the import data
          } else {
            const status = bc[statusIdFldIdx]
            const siteId = bc[bcSiteIdFieldIdx]
            const siteIdx = sitesFull.findIndexById(siteId)
            const siteStatus = siteIdx >= 0 && sitesFull.getList()[siteIdx][siteStatusFldIdx]
            let valueValidation = true
            let valueTooManyItemsValidation = true
            let valueNoItemValidation = true
            let keyFieldModified = false
            const checkIfNotModified = REQUIRED_STATUS_FOR_DISABLE_ANY_CHANGES.includes(status)
            const checkIfNeedToChangeStatus = CHANGE_STATUS_ON_IMPORT.includes(status)
            const checkIfModified = checkIfNotModified || checkIfNeedToChangeStatus

            const fieldsToUpdate = row.reduce((res, value, index) => {
              const fieldIdx = idxs[index]
              const fieldId = fieldIdx >= 0 ? createColumns[fieldIdx]?.id : null
              if (fieldId) {
                const isEditable = isBCFieldEditableFunction(createColumns, [ bcCopy ], 'createBC')(fieldIdx, 0)
                if (isEditable) {
                  const type = createColumns[fieldIdx].type

                  if (fieldId === BC_SECTORS_FIELD) {
                    const validated = validateSectors(value, siteName, siteStatus, calcMathod)
                    valueTooManyItemsValidation = validated.valueTooManyItemsValidation
                    valueNoItemValidation = validated.valueNoItemValidation
                    if (valueNoItemValidation && valueTooManyItemsValidation) {
                      value = validated.value
                      // Update sector ids
                      res[BC_SECTOR_IDS_FIELD] = validated.sectorIds
                    }
                  }

                  if (value) {
                    // Process neighbors
                    if (fieldId === BC_NEIGHBORS_FIELD) {
                      try {
                        // Convert to JSON object
                        value = JSON.parse(value)
                      } catch (e) {
                        valueValidation = false
                      }
                    }

                    const parsed = parseToType(type, value)
                    const checked = checkLimits(createColumns[fieldIdx], parsed)
                    if (!checked) {
                      valueValidation = false
                    }
                    res[fieldId] = parsed

                    bcCopy[fieldIdx] = parsed // Update copy to correctly check for editability

                    if (checkIfModified && !keyFieldModified) {
                      if (checkIfNotModified || FIELDS_TO_CHANGE_STATUS_ON_IMPORT.includes(fieldId)) {
                        if (!fieldIndexes[fieldId]) {
                          fieldIndexes[fieldId] = findIndex(fields, fieldId)
                        }
                        const fldIdx = fieldIndexes[fieldId]
                        if (fldIdx >= 0) {
                          const originValue = bc[fldIdx]
                          if (originValue instanceof Object) {
                            keyFieldModified =
                              JSON.stringify(parsed) !== JSON.stringify(originValue.data || originValue)
                          } else {
                            keyFieldModified = parsed !== originValue
                          }
                        }
                      }
                    }
                  }
                }
              }
              return res
            }, {})

            if (keyFieldModified && checkIfNotModified) {
              modificationNotAllowed++
            } else if (!valueValidation) {
              incorrectValue.push(bcName)
            } else if (!valueTooManyItemsValidation) {
              tooManyItemsValue.push(bcName)
            } else if (!valueNoItemValidation) {
              noItemValue.push(bcName)
            } else {
              const groupCaseId = bc[gcIdFieldIdx]
              if (!businessCases[groupCaseId]) {
                businessCases[groupCaseId] = []
              }
              const imported = {
                [BC_ID_FIELD]: bc[idFieldIdx],
                [BC_SITE_ID_FIELD]: bc[bcSiteIdFieldIdx],
                [BC_SITE_PROJECT_ID_FIELD]: projectId,
                ...fieldsToUpdate,
              }
              if (keyFieldModified && checkIfNeedToChangeStatus) {
                imported[BC_STATUS_ID_FIELD] = BC_STATUS_ID.NEW
                imported[BC_STATUS_NAME_FIELD] = undefined
              }
              businessCases[groupCaseId].push(imported)
              if (!toUpdate[groupCaseId]) {
                toUpdate[groupCaseId] = 0
              }
              toUpdate[groupCaseId]++

              alreadyUpdated.push(bcName)
            }
          }
        } else {
          // The name not found. Creating new BC
          let notDuplicate = true
          const bcIdWithSite = full.findRangeByValue(siteName, BC_SITE_FIELD)
          if (bcIdWithSite) {
            notDuplicate = bcIdWithSite.reduce((agg, idx) => {
              const bcWithSiteName = full.getList()[idx]
              return agg && (bcName === bcWithSiteName[bcNameFldIdx] || gcName !== bcWithSiteName[gcNameFldIdx])
            }, true)
          }
          if (!notDuplicate) {
            duplicateSite++
          } else {
            const siteIdxs = sitesFull.findRangeByValue(siteName, SITE_NAME_FIELD)
            if (siteIdxs) {
              const groupCaseId = groupCaseNames.find((name) => name.name === gcName)?.id
              const siteId = sitesFull.getList()[siteIdxs[0]][siteIdFldIdx]
              const siteStatus = sitesFull.getList()[siteIdxs[0]][siteStatusFldIdx]

              let valueValidation = true
              let valueTooManyItemsValidation = true
              let valueNoItemValidation = true
              const fieldsToCreate = row.reduce((res, orValue, index) => {
                let value = orValue
                const fieldIdx = idxs[index]
                const field = createColumns[fieldIdx]
                const fieldId = field?.id

                const editable = field?.editable
                const editableDraft = field?.editableDraft
                const editableInBcMethods = field?.editableInBcMethods

                const isEditable = calcMathod !== CALC_METHOD_POSTLAUNCH /* Do not check for other calc methods */ ||
                  editable || editableDraft || editableInBcMethods?.includes(CALC_METHOD_POSTLAUNCH)
                if (fieldIdx >= 0 && fieldId && isEditable) {
                  const type = field.type

                  if (fieldId === BC_SECTORS_FIELD) {
                    const validated = validateSectors(value, siteName, siteStatus, calcMathod)
                    valueTooManyItemsValidation = validated.valueTooManyItemsValidation
                    valueNoItemValidation = validated.valueNoItemValidation
                    if (valueNoItemValidation && valueTooManyItemsValidation) {
                      value = validated.value
                      if (validated.sectorIds) {
                        // Update sector ids
                        res[BC_SECTOR_IDS_FIELD] = validated.sectorIds
                      }
                    }
                  }

                  if (value) {
                    if (fieldId === BC_NEIGHBORS_FIELD) {
                      try {
                        // Convert to JSON object
                        value = JSON.parse(value)
                      } catch (e) {
                        valueValidation = false
                      }
                    }

                    const parsed = parseToType(type, value)
                    const checked = checkLimits(field, parsed)
                    if (!checked) {
                      valueValidation = false
                    }
                    res[fieldId] = parsed
                  }
                }
                return res
              }, {})
              if (!valueValidation) {
                incorrectValue.push(bcName)
              } else if (!groupCaseId) {
                cantCreateBCG.push(bcName)
              } else if (!valueTooManyItemsValidation) {
                tooManyItemsValue.push(bcName)
              } else if (!valueNoItemValidation) {
                noItemValue.push(bcName)
              } else if (bcName && alreadyUpdated.includes(bcName)) {
                duplicateBCName++
              } else {
                const newBC = {
                  [BC_ID_FIELD]: uuid(),
                  [BC_SITE_ID_FIELD]: siteId,
                  [BC_SITE_PROJECT_ID_FIELD]: projectId,
                  [BC_STATUS_ID_FIELD]: BC_STATUS_ID.NEW,
                  [BC_STATUS_NAME_FIELD]: BC_STATUS_NEW_NAME,
                  ...fieldsToCreate,
                }
                if (!newBC[BC_CALC_METHOD_FIELD]) {
                  newBC[BC_CALC_METHOD_FIELD] = getEnumDefValue(fields, BC_CALC_METHOD_FIELD)
                }

                if (!businessCases[groupCaseId]) {
                  businessCases[groupCaseId] = []
                }

                businessCases[groupCaseId].push(newBC)
                if (!toCreate[groupCaseId]) {
                  toCreate[groupCaseId] = 0
                }
                toCreate[groupCaseId]++

                alreadyUpdated.push(bcName)
              }
            } else {
              noSite++
            }
          }
        }
      }
      return businessCases
    }, {})

    let created = 0
    let updated = 0
    let serverError = 0
    let serverErrorMessage = null
    if (businessCases) {
      const groupCaseIds = Object.keys(businessCases)
      for (const gcId of groupCaseIds) {
        let groupCaseId = gcId
        const gcbusinessCases = businessCases[gcId]
        if (gcbusinessCases.length > 0 && (!groupCaseId || groupCaseId === 'undefined')) {
          const createId = uuid()
          const result = await dispatch(doCreateGroupCase({ id: createId, projectId }))
          if (result.error) {
            serverError += toCreate[groupCaseId] || 0
            serverError += toUpdate[groupCaseId] || 0
            continue
          } else {
            groupCaseId = createId
          }
        }
        const result = await dispatch(doCreateBusinessCases({
          groupCaseId,
          businessCases: gcbusinessCases,
        }))
        if (!result.error) {
          created += toCreate[gcId] || 0
          updated += toUpdate[gcId] || 0
        } else {
          serverError += toCreate[gcId] || 0
          serverError += toUpdate[gcId] || 0
          serverErrorMessage = result.error.message
        }
      }
    }

    dispatch(setLoading(false))

    return {
      noMandatoryValue,
      incorrectStartCapexDate,
      incorrectStartRevenueDate,
      noSite,
      duplicateSite,
      created,
      updated,
      serverError,
      serverErrorMessage,
      incorrectValue,
      noItemValue,
      tooManyItemsValue,
      duplicateBCName,
      ignoredRows,
      modificationNotAllowed,
      incorrectDateFormat,
      duplicateNeighbors,
      cantCreateBCG,
    }
  }
}

export const deleteGC = (gcId) => async (dispatch, getState) => {
  const currentState = getState()
  if (!selectLoading(currentState)) {
    return Promise.all([
      dispatch(doDeleteGC({ id: gcId })),
    ])
  }
}

export const getBusinessCaseSummary = (businessCaseId) => async (dispatch, getState) => {
  const currentState = getState()
  if (!selectLoading(currentState)) {
    await Promise.all([
      dispatch(doGetBusinessCaseSummary(businessCaseId.id)),
      dispatch(doGetBusinessCaseFinancials(businessCaseId)),
    ])

    if (businessCaseId) {
      const full = selectBCFull(currentState)
      const fields = selectBCFields(currentState)
      const bc = full.findIndexById(businessCaseId.id)
      const corrFieldIndx = findIndex(fields, BC_CORRESPONDENCE_FIELD)
      const isCorrespondence = full.getList()[bc][corrFieldIndx]
      if (isCorrespondence !== null) {
        const corrDateFieldIndx = findIndex(fields, BC_CORRESPONDENCE_DATE_FIELD)
        const correspondenceDate = full.getList()[bc][corrDateFieldIndx]
        const currentDate = moment()
        const isExpired = currentDate.diff(moment(correspondenceDate), 'months', true) >= 3
        dispatch(setBCDifferencesExpired(isExpired))
        if (isCorrespondence && !isExpired) {
          dispatch(doGetBusinessCaseDifferences(businessCaseId))
        }
      } else {
        dispatch(setBCDifferencesExpired(false))
        dispatch(clearBCDifferencesData())
      }
    }
  }
}

export const calculateDraft = (payload) => async (dispatch, getState) => {
  const { gcId, businessCaseIds } = payload
  const result = await dispatch(doCalculateDraft({
    groupCaseId: gcId,
    businessCaseIds,
  }))
  if (!result.error) {
    return result.payload
    // const fakeResult = await dispatch(doCalculateFake({
    //   groupCaseId: gcId,
    //   businessCaseIds,
    // }))
    // if (!fakeResult.error) {
    //   return fakeResult.payload
    // } else {
    //   return {
    //     error: fakeResult.error.message,
    //   }
    // }
  } else {
    return {
      error: result.error.message,
    }
  }
}

export const recalculateDraft = (payload) => async (dispatch, getState) => {
  const { gcId, businessCaseIds } = payload
  const result = await dispatch(doRecalculateDraft({
    groupCaseId: gcId,
    businessCaseIds,
  }))
  if (!result.error) {
    return result.payload
  } else {
    return {
      error: result.error.message,
    }
  }
}

export const calculateForApprove = (payload) => async (dispatch, getState) => {
  const { gcId, businessCaseIds } = payload
  const result = await dispatch(doCalculateForApprove({
    groupCaseId: gcId,
    businessCaseIds,
  }))
  if (!result.error) {
    return result.payload
  } else {
    return {
      error: result.error.message,
    }
  }
}

export const cancelCalculation = (payload) => async (dispatch, getState) => {
  const currentState = getState()
  const full = selectCreateBCFull(currentState)
  const [ bcIdIndx,, gcIdIndx,, selectedIndx ] = selectCreateBCFldIdx(currentState)
  let groupCaseId = null
  const businessCaseIds = full.getList().reduce((res, row) => {
    if (row[selectedIndx]) {
      if (!groupCaseId) {
        groupCaseId = row[gcIdIndx]
      }
      res.push(row[bcIdIndx])
    }
    return res
  }, [])
  const result = await dispatch(doCancelCalculation({
    groupCaseId,
    businessCaseIds,
  }))
  if (!result.error) {
    return result.payload
  } else {
    return {
      error: result.error.message,
    }
  }
}

export const approve = (payload) => async (dispatch, getState) => {
  const { groupCaseId, businessCaseIds } = payload
  const result = await dispatch(doApprove({
    groupCaseId,
    businessCaseIds,
  }))
  if (!result.error) {
    return result.payload
  } else {
    return {
      error: result.error.message,
    }
  }
}

export const reject = (payload) => async (dispatch, getState) => {
  const { groupCaseId, businessCaseIds } = payload
  const result = await dispatch(doReject({
    groupCaseId,
    businessCaseIds,
  }))
  if (!result.error) {
    return result.payload
  } else {
    return {
      error: result.error.message,
    }
  }
}

export const getCalcCoefficients = () => async (dispatch, getState) => {
  const currentState = getState()
  if (!selectCalcCoefficientsLoading(currentState)) {
    return Promise.all([
      dispatch(doGetCalcCoefficients()),
    ])
  }
}

export const updateCalcCoefficients = (modified) => async (dispatch, getState) => {
  const currentState = getState()
  if (!selectCalcCoefficientsLoading(currentState)) {
    const result = await dispatch(doUpdateCalcCoefficients(modified))
    if (result.error) {
      return {
        success: false,
        message: result.error.message,
      }
    }
    return {
      success: true,
    }
  }
}

export const getBCIdBySiteId = (siteId) => (_, getState) => {
  const currentState = getState()
  const full = selectBCFull(currentState)
  const range = full.findRangeByValue(siteId, BC_SITE_ID_FIELD)
  if (range?.length > 0) {
    const fields = selectBCFields(currentState)
    const idFieldIdx = findIndex(fields, BC_ID_FIELD)
    return range.map((index) => {
      const bc = full.getList()[index]
      return bc[idFieldIdx]
    })
  }
  return null
}

export const getBCIdBySiteIds = (siteIds) => (_, getState) => {
  const currentState = getState()
  const full = selectBCFull(currentState)
  const fields = selectBCFields(currentState)
  const idFieldIdx = findIndex(fields, BC_ID_FIELD)
  const ids = siteIds.reduce((agg, siteId) => {
    const range = full.findRangeByValue(siteId, BC_SITE_ID_FIELD)
    if (range?.length > 0) {
      range.forEach((index) => {
        const bc = full.getList()[index]
        agg.push(bc[idFieldIdx])
      })
    }
    return agg
  }, [])
  return ids.length > 0 ? ids : null
}

export const getBCIdBySectorId = (sectorId) => (dispatch, getState) => {
  const currentState = getState()
  const sectorsFull = selectSectorsFull(currentState)
  const sectorsFields = selectSectorFields(currentState)
  const sectorSiteIdFieldIdx = findIndex(sectorsFields, SECTOR_SITE_FIELD)
  const sectorIndex = sectorsFull.findIndexById(sectorId)
  const siteId = sectorsFull.getList()[sectorIndex]?.[sectorSiteIdFieldIdx]
  return dispatch(getBCIdBySiteId(siteId))
}

export const getBCIdBySectorIds = (sectorIds) => (dispatch, getState) => {
  const currentState = getState()
  const sectorsFull = selectSectorsFull(currentState)
  const sectorsFields = selectSectorFields(currentState)
  const sectorSiteIdFieldIdx = findIndex(sectorsFields, SECTOR_SITE_FIELD)
  const ids = sectorIds.reduce((agg, sectorId) => {
    const sectorIndex = sectorsFull.findIndexById(sectorId)
    if (sectorIndex >= 0) {
      agg.add(sectorsFull.getList()[sectorIndex][sectorSiteIdFieldIdx])
    }
    return agg
  }, new Set())
  return dispatch(getBCIdBySiteIds(Array.from(ids)))
}

const intersect = (left, right) => {
  return left.filter((el) => right.indexOf(el) !== -1)
}

export const getIncludesSitesInBC = (siteIds, sectors) => (_, getState) => {
  const currentState = getState()
  const full = selectBCFull(currentState)
  const fullList = full.getList()
  const fields = selectBCFields(currentState)
  const sectorsIdx = findIndex(fields, BC_SECTOR_IDS_FIELD)
  const isSectors = !!sectors
  const ids = siteIds.reduce((agg, siteId) => {
    const range = full.findRangeByValue(siteId, BC_SITE_ID_FIELD)
    if (range?.length > 0) {
      if (isSectors) { // проверка секторов на включение в BC
        range.forEach((index) => {
          const bc = fullList[index]
          const sectorsBC = bc[sectorsIdx] ? bc[sectorsIdx].split(',') : []
          const sectorsInclude = intersect(sectors[siteId], sectorsBC)
          sectorsInclude.forEach((sectorId) => agg.push(sectorId))
        })
      } else {
        agg.push(siteId)
      }
    }
    return agg
  }, [])
  return ids.length > 0 ? ids : null
}

export const getIncludeSectorsInBC = (sectorIds) => (dispatch, getState) => {
  const currentState = getState()
  const sectorsFull = selectSectorsFull(currentState)
  const sectorsList = sectorsFull.getList()
  const sectorsFields = selectSectorFields(currentState)
  const sectorSiteIdFieldIdx = findIndex(sectorsFields, SECTOR_SITE_FIELD)
  const sectors = {}
  const ids = sectorIds.reduce((agg, sectorId) => {
    const sectorIndex = sectorsFull.findIndexById(sectorId)
    if (sectorIndex >= 0) {
      const siteId = sectorsList[sectorIndex][sectorSiteIdFieldIdx]
      if (!agg.has(siteId)) {
        sectors[siteId] = []
        agg.add(siteId)
      }
      sectors[siteId].push(sectorId)
    }
    return agg
  }, new Set())
  return dispatch(getIncludesSitesInBC(Array.from(ids), sectors))
}

export const checkNetworkItemByBCStatus = (
  id, type, statuses = [ BC_STATUS_ID.APPROVED ], reverse = false,
) => (_, getState) => {
  const currentState = getState()
  let siteId = null
  let networkItemName = null
  if (type === 'rbs') {
    const full = selectBaseStationsFull(currentState)
    const fields = selectBaseStationFields(currentState)
    const siteIdFldIdx = findIndex(fields, RBS_SITE_FIELD)
    const nameFldIdx = findIndex(fields, RBS_NAME_FIELD)
    const itemIndex = full.findIndexById(id)
    const item = full.getList()[itemIndex]
    siteId = item[siteIdFldIdx]
    networkItemName = item[nameFldIdx]
  } else if (type === 'sector') {
    const full = selectSectorsFull(currentState)
    const fields = selectSectorFields(currentState)
    const siteIdFldIdx = findIndex(fields, SECTOR_SITE_FIELD)
    const nameFldIdx = findIndex(fields, SECTOR_NAME_FIELD)
    const itemIndex = full.findIndexById(id)
    const item = full.getList()[itemIndex]
    siteId = item[siteIdFldIdx]
    networkItemName = item[nameFldIdx]
  } else if (type === 'site') {
    siteId = id
    const full = selectSitesFull(currentState)
    const fields = selectSiteFields(currentState)
    const nameFldIdx = findIndex(fields, SECTOR_NAME_FIELD)
    const itemIndex = full.findIndexById(id)
    const item = full.getList()[itemIndex]
    networkItemName = item[nameFldIdx]
  }

  const full = selectBCFull(currentState)
  const range = full.findRangeByValue(siteId, BC_SITE_ID_FIELD)
  if (range?.length > 0) {
    const fields = selectBCFields(currentState)
    const statusIdFldIdx = findIndex(fields, BC_STATUS_ID_FIELD)
    const rbssFieldIdx = findIndex(fields, BC_RBSS_FIELD)
    const sectorsFieldIdx = findIndex(fields, BC_SECTORS_FIELD)
    const fullList = full.getList()
    const index = range.findIndex((index) => {
      const bc = fullList[index]
      const inStatuses = statuses.includes(bc[statusIdFldIdx])
      if (reverse ? !inStatuses : inStatuses) {
        if (type === 'site') {
          return true
        } else if (type === 'rbs') {
          const rbssBC = bc[rbssFieldIdx]?.split(',')?.map((item) => item.trim()) || []
          return rbssBC.includes(networkItemName)
        } else if (type === 'sector') {
          const rbssBC = bc[sectorsFieldIdx]?.split(',')?.map((item) => item.trim()) || []
          return rbssBC.includes(networkItemName)
        }
      }
      return false
    })
    return index >= 0
  }
  return false
}

export const getTechInfo = (bcId) => async (dispatch, getState) => {
  const currentState = getState()
  const sectorsFields = selectSectorFields(currentState)
  const sectorSiteIdFieldIdx = findIndex(sectorsFields, SECTOR_TECH_FIELD)

  const sectorsData = await dispatch(getBCSectors(bcId))

  if (sectorsData) {
    const result = {}
    sectorsData.payload.data.forEach((sector) => {
      const tech = sector[sectorSiteIdFieldIdx]
      if (result[tech] === undefined) {
        result[tech] = 0
      }
      result[tech] += 1
    })
    return result
  }
  return null
}

export const getListBCData = (list) => async (dispatch, getState) => {
  await dispatch(doGetListBCData(list))
  await dispatch(prepareBCNeighbors)
}

export const reloadDefaultBC = () => async (dispatch, getState) => {
  await dispatch(loadDefaultBCData())
  const currentState = getState()
  const defaultBC = selectDefaultBC(currentState)
  const full = selectBCFull(currentState)
  const fields = selectBCFields(currentState)
  const bcNameFldIdx = findIndex(fields, BC_NAME_FIELD)
  const fullList = full.getList()
  fullList.forEach((bc) => {
    const name = bc[bcNameFldIdx]
    bc.isInMyProject = defaultBC ? !defaultBC.includes(name) : true
  })
  dispatch(redrawBusinessCases())
}

export const hideEditNeighbors = () => async (dispatch) => {
  dispatch(showEditNeighbors(null))
}

export const addSiteToNeighbors = (targetsList) => (dispatch, getState) => {
  const currentState = getState()
  const modifiedNeighbors = selectModifiedNeighbors(currentState)
  const selectedZone = selectSelectedModificationZone(currentState)
  let newNeighborsByTech = null
  let newModifiedValue = null
  let added = false
  if (targetsList?.length === 1 && targetsList[0].type === 'site') {
    const siteId = targetsList[0].id
    const sectorsFull = selectSectorsFull(currentState)
    const sectorsFields = selectSectorFields(currentState)
    const sectorTechFldIdx = findIndex(sectorsFields, SECTOR_TECH_FIELD)
    const sectorNameFldIdx = findIndex(sectorsFields, SECTOR_NAME_FIELD)
    const sectorStatusFldIdx = findIndex(sectorsFields, SECTOR_STATUS_FIELD)
    const siteIdxs = sectorsFull.findRangeByValue(siteId, SECTOR_SITE_FIELD)
    if (siteIdxs?.length > 0) {
      newNeighborsByTech = siteIdxs.reduce((agg, idx) => {
        const sectorStatus = sectorsFull.getList()[idx]?.[sectorStatusFldIdx]
        if (sectorStatus === STATUS_ACTIVE) {
          const sectorTech = sectorsFull.getList()[idx]?.[sectorTechFldIdx]
          const sectorName = sectorsFull.getList()[idx]?.[sectorNameFldIdx]
          if (!agg[sectorTech]) {
            agg[sectorTech] = []
          }
          agg[sectorTech] = [ ...agg[sectorTech], sectorName ]
          added = true
        }
        return agg
      }, {})
    }
  } else {
    if (selectedZone?.type === 'sector' || selectedZone?.type === 'tech') {
      const tech = (selectedZone?.type === 'tech') ? selectedZone.name : selectedZone.tech
      const sector = (selectedZone?.type === 'tech') ? null : selectedZone.name
      // Add all neighbors to sector or tech
      targetsList.forEach((target) => {
        const newNeighborSector = target.name
        if (sector) {
          if (!modifiedNeighbors[tech][sector].includes(newNeighborSector)) {
            if (!newModifiedValue) {
              newModifiedValue = { ...modifiedNeighbors }
            }
            newModifiedValue = {
              ...newModifiedValue,
              [tech]: {
                ...newModifiedValue[tech],
                [sector]: [ ...(newModifiedValue[tech][sector] || []), newNeighborSector ],
              },
            }
            added = true
          }
        } else {
          Object.keys(modifiedNeighbors[tech])?.forEach((sector) => {
            if (!modifiedNeighbors[tech][sector].includes(newNeighborSector)) {
              if (!newModifiedValue) {
                newModifiedValue = { ...modifiedNeighbors }
              }
              newModifiedValue = {
                ...newModifiedValue,
                [tech]: {
                  ...newModifiedValue[tech],
                  [sector]: [ ...(newModifiedValue[tech][sector] || []), newNeighborSector ],
                },
              }
              added = true
            }
          })
        }
      })
    } else {
      newNeighborsByTech = targetsList.reduce((agg, target) => {
        if (target.type === 'sector') {
          const sectorTech = target.technology
          const sectorName = target.name
          if (!agg[sectorTech]) {
            agg[sectorTech] = []
          }
          agg[sectorTech] = [ ...agg[sectorTech], sectorName ]
          added = true
        }
        return agg
      }, {})
    }
  }

  if (newNeighborsByTech) {
    // Add neighbors to sectors with the same technology
    newModifiedValue = Object.keys(modifiedNeighbors).reduce((agg, tech) => {
      agg[tech] = Object.keys(modifiedNeighbors[tech]).reduce((techAgg, sector) => {
        const newSectors = newNeighborsByTech[tech]?.filter((sec) => !modifiedNeighbors[tech][sector].includes(sec))
        techAgg[sector] = [ ...modifiedNeighbors[tech][sector], ...(newSectors || []) ]
        if (newSectors?.length > 0) {
          added = true
        }
        return techAgg
      }, {})
      return agg
    }, {})
    // Add neighbors to all sectors if the technology not found
    Object.keys(newNeighborsByTech).forEach((tech) => {
      if (!newModifiedValue[tech]) {
        Object.keys(newModifiedValue).forEach((newTech) => {
          Object.keys(newModifiedValue[newTech]).forEach((sector) => {
            const newSectors = newNeighborsByTech[tech]
              .filter((sec) => !newModifiedValue[newTech][sector].includes(sec))
            if (newSectors?.length > 0) {
              newModifiedValue[newTech][sector] = [ ...newModifiedValue[newTech][sector], ...newSectors ]
              added = true
            }
          })
        })
      }
    })
  }
  if (added && newModifiedValue) {
    dispatch(setModifiedNeighbors(newModifiedValue))
    dispatch(setIsModifiedNeighbors(true))
  } else {
    toast.error('No neighbors added')
  }
}

export const addSectorToNeighbors = (target) => (dispatch, getState) => {
  const currentState = getState()
  const selectedZone = selectSelectedModificationZone(currentState)
  const modifiedNeighbors = selectModifiedNeighbors(currentState)
  let newModifiedValue = null
  const newNeighborSector = target.name
  if (selectedZone?.type === 'sector') {
    const sector = selectedZone.name
    const tech = selectedZone.tech
    // Add neighbor to sector
    if (!modifiedNeighbors[tech][sector].includes(newNeighborSector)) {
      newModifiedValue = {
        ...modifiedNeighbors,
        [tech]: {
          ...modifiedNeighbors[tech],
          [sector]: [ ...(modifiedNeighbors[tech][sector] || []), newNeighborSector ],
        },
      }
    }
  } else {
    const tech = (selectedZone?.type === 'tech') ? selectedZone.name : target.technology
    if (modifiedNeighbors?.[tech]) {
      let added = false
      // Add neighbor to all sectors with tech
      const techObject = Object.keys(modifiedNeighbors[tech]).reduce((agg, sector) => {
        if (!modifiedNeighbors[tech][sector].includes(newNeighborSector)) {
          agg[sector] = [ ...modifiedNeighbors[tech][sector], newNeighborSector ]
          added = true
        }
        return agg
      }, {})
      if (added) {
        newModifiedValue = {
          ...modifiedNeighbors,
          [tech]: { ...techObject },
        }
      }
    } else {
      // Tech not found, add neighbor to all sectors
      let added = false
      const modified = Object.keys(modifiedNeighbors).reduce((agg, tech) => {
        agg[tech] = Object.keys(modifiedNeighbors[tech]).reduce((techAgg, sector) => {
          if (!modifiedNeighbors[tech][sector].includes(newNeighborSector)) {
            techAgg[sector] = [ ...modifiedNeighbors[tech][sector], newNeighborSector ]
            added = true
          }
          return techAgg
        }, {})
        return agg
      }, {})
      if (added) {
        newModifiedValue = modified
      }
    }
  }
  if (newModifiedValue) {
    dispatch(setModifiedNeighbors(newModifiedValue))
    dispatch(setIsModifiedNeighbors(true))
  } else {
    toast.error('No neighbor added')
  }
}

export const insertNeighborsFromRawText = (techName, sectorName, rawValue) => (dispatch, getState) => {
  if (!rawValue || rawValue.trim().length === 0) {
    toast.error('No neighbors added', { position: 'top-right' })
    return false
  }
  const values = new Set(rawValue.split(/[\s,.:;]+/).map((value) => value.replace(/["'‘’“”(){}[\]<>«»〈〉⟨⟩″′]/g, '')))
  const currentState = getState()
  const editNeighborsForBC = selectEditNeighborsForBC(currentState)
  const createBcFields = selectCreateBCFields(currentState)
  const modifiedNeighbors = selectModifiedNeighbors(currentState)
  const currentNeighbors = modifiedNeighbors[techName][sectorName]
  const bcSiteIdFldIdx = findIndex(createBcFields, BC_SITE_ID_FIELD)
  const selectedSiteId = editNeighborsForBC?.bc?.[bcSiteIdFldIdx]
  const sectorsFull = selectSectorsFull(currentState)
  const sectorsFields = selectSectorFields(currentState)
  const sectorSiteIdFldIdx = findIndex(sectorsFields, SECTOR_SITE_FIELD)
  const sectorStatusFldIdx = findIndex(sectorsFields, SECTOR_STATUS_FIELD)
  let foundSector = false
  const activeSectors = [ ...values ].filter((value) => {
    const sectorIdx = sectorsFull.findRangeByValue(value, SECTOR_NAME_FIELD)
    if (sectorIdx?.length > 0) {
      foundSector = true
      const sector = sectorsFull.getList()[sectorIdx[0]]
      if (sector) {
        const status = sector[sectorStatusFldIdx]
        const siteId = sector[sectorSiteIdFldIdx]
        return status === STATUS_ACTIVE && siteId !== selectedSiteId && !currentNeighbors?.includes(value)
      }
    }
    return false
  })
  if (activeSectors.length === 0) {
    if (foundSector) {
      toast.error('No neighbors added', { position: 'top-right' })
    }
    return false
  }

  const newModifiedNeighbors = {
    ...modifiedNeighbors,
    [techName]: {
      ...modifiedNeighbors[techName],
      [sectorName]: [ ...currentNeighbors, ...activeSectors ],
    },
  }

  dispatch(setModifiedNeighbors(newModifiedNeighbors))
  dispatch(setIsModifiedNeighbors(true))
  if (activeSectors.length === 1) {
    toast.success('One neighboring sector has been added', { position: 'top-right' })
  } else {
    toast.success(`${activeSectors.length} neighboring sectors have been added`, { position: 'top-right' })
  }

  return true
}

export const createBCForSite = (draftSite, history) => (dispatch, getState) => {
  const state = getState()
  dispatch(wrapByEventLog({
    type: EVENT_TYPE.bcCreate,
    action: async () => {
      const activePanel = selectPanel(state)
      if (activePanel) {
        dispatch(setPanel(null))
      }
      const projectId = await dispatch(ensureUserProject())
      if (projectId === '_') {
        await dispatch(saveEventLog(
          EVENT_TYPE.bcCreate,
          ': Default project is used while creating new Business Case',
          STATUS_ERROR,
        ))
        toast.error(NO_PROJECT_MESSAGE)
        throw new Error(NO_PROJECT_MESSAGE)
      }
      const res = await dispatch(createNewGroupCase({}))
      const gcId = res ? res.id : null
      if (gcId) {
        const { id: siteId, name: siteName, draft } = draftSite
        const createBcFields = selectCreateBCFields(state)
        const fields = createBcFields && createBcFields.length > 0
          ? createBcFields
          : getCreateBCFields(selectDefBCFields(state))
        const bcRow = bcObjectToRow(fields)
        const bcObject = rowToBCObject(bcRow, fields, siteId, projectId)
        const bc = {
          ...bcObject,
          [BC_SITE_ID_FIELD]: siteId,
          [BC_SITE_FIELD]: siteName,
          [BC_SITE_PROJECT_ID_FIELD]: projectId,
        }
        if (!draft) {
          const sectorsFull = selectSectorsFull(state)
          const sectorsFields = selectSectorFields(state)
          const sectorStatusFldIdx = findIndex(sectorsFields, SECTOR_STATUS_FIELD)
          const sectorIndexes = sectorsFull.findRangeByValue(siteId, SECTOR_SITE_FIELD)
          const hasDraftSector = sectorIndexes?.findIndex((sectorIdx) => {
            const sector = sectorsFull.getList()[sectorIdx]
            const status = sector[sectorStatusFldIdx]
            return status === STATUS_DRAFT
          }) >= 0
          if (!hasDraftSector) {
            bc[BC_CALC_METHOD_FIELD] = CALC_METHOD_POSTLAUNCH
            bc[BC_CASE_TYPE_FIELD] = CASE_TYPE_B2C
          } else {
            bc[BC_CALC_METHOD_FIELD] = CALC_METHOD_NEW_SECTORS
            bc[BC_CASE_TYPE_FIELD] = CASE_TYPE_B2B
          }
        }
        const result = await dispatch(doCreateBusinessCases({
          groupCaseId: gcId,
          businessCases: [ bc ],
        }))
        if (!result.error) {
          await dispatch(loadAllBCData())
          history?.push(`/${projectId}/business-cases/${gcId}`)
        } else {
          throw new Error(result.error)
        }
      }
    },
  }))
}

export const updateBCNeighbors = (id, value) => (_, getState) => {
  const state = getState()
  const list = selectCreateBCList(state)
  const fields = selectCreateBCFields(state)
  const neighborsColIndex = findIndex(fields, BC_NEIGHBORS_FIELD)
  const idx = list.findIndexById(id)
  if (idx >= 0) {
    const newBc = [ ...list.getList()[idx] ]
    newBc[neighborsColIndex] = getNeighborsInfo(value)
    list.getList()[idx] = newBc
  } else {
    console.error('Unable to apply changes to BC neighbors: can\'t find BC in the list by Id')
  }
}

export default bcSlice.reducer
