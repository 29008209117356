import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Slider, Stack } from '@fluentui/react'
import { selectSettings, saveSettings } from '../../../../features/settings/settingsSlice'
import { elementsRedraw } from '../../../../features/network/networkSlice'
import Toggle from '../../../common/Toggle'
import Subheader from '../../../common/Subheader'

export const ID_RULER_TEXT = 'ruler-text'

const RulerSettings = () => {
  const dispatch = useDispatch()

  const ruler = useSelector(selectSettings).ruler

  const onChangeRulerTextSize = useCallback((value) => {
    dispatch(saveSettings({
      ruler: {
        ...ruler,
        textSize: value,
      },
    }))
    dispatch(elementsRedraw())
  }, [ dispatch, ruler ])

  const onChangeRulerLabelOpacity = useCallback((value) => {
    dispatch(saveSettings({
      ruler: {
        ...ruler,
        labelOpacity: value,
      },
    }))
    dispatch(elementsRedraw())
  }, [ dispatch, ruler ])

  const handleToggle = useCallback(() => {
    dispatch(saveSettings({
      ruler: {
        ...ruler,
        hideIntermediateMeasures: !ruler?.hideIntermediateMeasures,
      },
    }))
    dispatch(elementsRedraw())
  }, [ dispatch, ruler ])

  const handleAnglesToggle = useCallback(() => {
    dispatch(saveSettings({
      ruler: {
        ...ruler,
        showAngles: !ruler?.showAngles,
      },
    }))
    dispatch(elementsRedraw())
  }, [ dispatch, ruler ])

  return (
    <Stack className="full-width" style={{ gap: 16 }}>
      <Stack.Item style={{ marginTop: 16 }}>
        <Slider
          label="Text Size"
          min={8}
          max={22}
          step={1}
          value={ruler?.textSize ?? 10}
          showValue
          snapToStep
          onChange={onChangeRulerTextSize}
        />
      </Stack.Item>
      <Stack.Item style={{ marginTop: 16 }}>
        <Slider
          label="Label Opacity"
          min={0}
          max={1}
          step={0.1}
          value={ruler?.labelOpacity ?? 0.8}
          showValue
          snapToStep
          onChange={onChangeRulerLabelOpacity}
        />
      </Stack.Item>
      <Stack.Item>
        <Subheader text="Intermediate Measurements" />
        <Toggle onText="On" offText="Off" checked={!ruler?.hideIntermediateMeasures} onChange={handleToggle} />
      </Stack.Item>
      <Stack.Item style={{ marginBottom: 16 }}>
        <Subheader text="Show Angles" />
        <Toggle onText="On" offText="Off" checked={ruler?.showAngles} onChange={handleAnglesToggle} />
      </Stack.Item>
    </Stack>
  )
}

export default RulerSettings
