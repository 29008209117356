export const cropByParentItems = (array, parentIdIdx, parentList) => {
  return parentList && parentIdIdx >= 0
    ? (parentList.getList().length > 0
        ? array.filter((item) => {
          const parentId = item[parentIdIdx]
          return parentId && parentList.findIndexById(parentId) >= 0
        })
        : []
      )
    : array
}

export const cropByChildrenItems = (array, itemIdIdx, childrenList, indexId) => {
  return childrenList
    ? (childrenList.getList().length > 0
        ? array.filter((item) => {
          return childrenList.findRangeByValue(item[itemIdIdx], indexId)?.length > 0
        })
        : []
      )
    : array
}
