import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Stack } from '@fluentui/react'
import {
  saveSettings, clearAllSettings, selectSettingsDeepFiltrationUp,
  selectSettingsDeepFiltrationDown,
} from '../../../../features/settings/settingsSlice'
import {
  elementsRedraw, filterSites, filterBaseStations, filterSectors,
  filterSitesUp, filterBaseStationsUp,
} from '../../../../features/network/networkSlice'
import Subheader from '../../../common/Subheader'
import Checkbox from '../../../common/Checkbox'

const DeepFiltration = () => {
  const dispatch = useDispatch()

  const deepFiltrationUp = useSelector(selectSettingsDeepFiltrationUp)
  const deepFiltrationDown = useSelector(selectSettingsDeepFiltrationDown)

  const handleCheck = useCallback((event, value) => {
    const doChanges = async () => {
      const deepFiltration = {
        up: deepFiltrationUp,
        down: deepFiltrationDown,
      }
      deepFiltration[event.currentTarget.id] = value
      document.body.classList.add('waiting')
      await dispatch(saveSettings({ deepFiltration }))
      setTimeout(async () => {
        try {
          await dispatch(filterSites())
          await dispatch(filterBaseStations())
          await dispatch(filterSectors())
          await dispatch(elementsRedraw())
          if (deepFiltration.up) {
            await dispatch(filterBaseStationsUp())
            await dispatch(filterSitesUp())
          }
        } finally {
          document.body.classList.remove('waiting')
        }
      }, 50)
    }

    doChanges()
  }, [ dispatch, deepFiltrationUp, deepFiltrationDown ])

  const dblClick = useCallback((e) => {
    if (e.shiftKey) {
      console.log('Clear all preferences!')
      dispatch(clearAllSettings())
    }
  }, [ dispatch ])

  return (
    <Stack style={{ paddingBottom: 16 }} onDoubleClick={dblClick}>
      <Subheader text="Direction of filtration" />
      <Checkbox id="down" label="Down" checked={deepFiltrationDown} onChange={handleCheck} />
      <Checkbox id="up" label="Up" checked={deepFiltrationUp} onChange={handleCheck} />
    </Stack>
  )
}

export default DeepFiltration
